// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HomeSlider from "../Home/HomeSlider";

function LonginComponent() {
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  console.log(slider_list);

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();



  const ary =[
    {
      "_id": "665acb084a75d84c758812f5",
      "userId": {
        "_id": "665965064a75d84c7575f493",
        "fullName": "Pashan ali",
        "referId": "RIL223852521"
      },
      "grand_total": 12794,
      "createdAt": "2024-06-01T07:17:28.185Z",
      "commision": 127.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 127.94"
    },
    {
      "_id": "665ad1c44a75d84c75885135",
      "userId": {
        "_id": "6650dbac832396d4115137c4",
        "referId": "RIL30261410",
        "fullName": "Halima  Khatun "
      },
      "grand_total": 14493,
      "createdAt": "2024-06-01T07:46:12.702Z",
      "commision": 289.86,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 144.93 and MARQUISE = 1% = 144.93"
    },
    {
      "_id": "665b04754a75d84c7589d2a8",
      "userId": {
        "_id": "665af91e4a75d84c75898145",
        "fullName": "Bikash Timung",
        "referId": "RIL209638542"
      },
      "grand_total": 3898,
      "createdAt": "2024-06-01T11:22:29.534Z",
      "commision": 58.47,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 58.47"
    },
    {
      "_id": "665b51d94a75d84c758b4482",
      "userId": {
        "_id": "6650dbac832396d411513568",
        "referId": "RIL29651474",
        "fullName": "Hiranya kumar  Gogoi"
      },
      "grand_total": 1699,
      "createdAt": "2024-06-01T16:52:41.895Z",
      "commision": 42.475,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 25.485 and MARQUISE = 1% = 16.990000000000002"
    },
    {
      "_id": "665b66bc4a75d84c758b76d1",
      "userId": {
        "_id": "665ad0924a75d84c758844db",
        "fullName": "SURAJ PEGU",
        "referId": "RIL713146406"
      },
      "grand_total": 40281,
      "createdAt": "2024-06-01T18:21:48.620Z",
      "commision": 402.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 402.81"
    },
    {
      "_id": "665bf93f4a75d84c758c6f32",
      "userId": {
        "_id": "665979de4a75d84c7577d590",
        "fullName": "Kabir ali",
        "referId": "RIL524585872"
      },
      "grand_total": 16691,
      "createdAt": "2024-06-02T04:46:55.848Z",
      "commision": 166.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 166.91"
    },
    {
      "_id": "665c45ac4a75d84c758e6e34",
      "userId": {
        "_id": "665c3b614a75d84c758e418d",
        "fullName": "Juri deka",
        "referId": "RIL702468561"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-02T10:13:00.218Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "665c79bf4a75d84c758f47a8",
      "userId": {
        "_id": "665b33d04a75d84c758ab077",
        "fullName": "Dominic teron",
        "referId": "RIL121998418"
      },
      "grand_total": 8545,
      "createdAt": "2024-06-02T13:55:11.788Z",
      "commision": 128.17499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 128.17499999999998"
    },
    {
      "_id": "665c82004a75d84c758f6946",
      "userId": {
        "_id": "665c78c64a75d84c758f436f",
        "fullName": "Mandip Barman",
        "referId": "RIL172516433"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-02T14:30:24.306Z",
      "commision": 124.44,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 124.44"
    },
    {
      "_id": "665c88be4a75d84c758f8b3b",
      "userId": {
        "_id": "665c81644a75d84c758f66dd",
        "fullName": "Priyakshi  buraguhain",
        "referId": "RIL503763341"
      },
      "grand_total": 8242,
      "createdAt": "2024-06-02T14:59:10.850Z",
      "commision": 206.05,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 123.63 and MARQUISE = 1% = 82.42"
    },
    {
      "_id": "665d88d3f9d7a312a956338d",
      "userId": {
        "_id": "665d7c10f9d7a312a955d751",
        "fullName": "Dibyajyoti Phukan",
        "referId": "RIL944512103"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-03T09:11:47.791Z",
      "commision": 165.92000000000002,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001 and MARQUISE = 1% = 82.96000000000001"
    },
    {
      "_id": "665d956bf9d7a312a95698b5",
      "userId": {
        "_id": "6650dbab832396d41151327a",
        "referId": "RIL28886850",
        "fullName": "Riksilbath Sangma"
      },
      "grand_total": 15891,
      "createdAt": "2024-06-03T10:05:31.091Z",
      "commision": 635.64,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 317.82 and MARQUISE = 2% = 317.82"
    },
    {
      "_id": "665d977af9d7a312a956aab3",
      "userId": {
        "_id": "6650dbac832396d4115134b3",
        "referId": "RIL294561621",
        "fullName": "Rehena Khatun"
      },
      "grand_total": 7095,
      "createdAt": "2024-06-03T10:14:18.204Z",
      "commision": 70.95,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 70.95"
    },
    {
      "_id": "665db52df9d7a312a95798c0",
      "userId": {
        "_id": "6650dbac832396d411513900",
        "referId": "RIL305812480",
        "fullName": "Mohidul  islam"
      },
      "grand_total": 15391,
      "createdAt": "2024-06-03T12:21:01.100Z",
      "commision": 153.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 153.91"
    },
    {
      "_id": "665dc1aef9d7a312a957ea52",
      "userId": {
        "_id": "665d668cf9d7a312a9552c99",
        "fullName": "Mirajul hoque",
        "referId": "RIL512049337"
      },
      "grand_total": 32882,
      "createdAt": "2024-06-03T13:14:22.418Z",
      "commision": 328.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.82"
    },
    {
      "_id": "665dd404f9d7a312a9583eaf",
      "userId": {
        "_id": "665c0e624a75d84c758d0d46",
        "fullName": "Wanshwami Dhar",
        "referId": "RIL413677726"
      },
      "grand_total": 33182,
      "createdAt": "2024-06-03T14:32:36.413Z",
      "commision": 663.64,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.82 and MARQUISE = 1% = 331.82"
    },
    {
      "_id": "665e0a64f9d7a312a958f59e",
      "userId": {
        "_id": "665d756cf9d7a312a955946f",
        "fullName": "Laishram Thoi singh",
        "referId": "RIL672362948"
      },
      "grand_total": 41281,
      "createdAt": "2024-06-03T18:24:36.437Z",
      "commision": 412.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 412.81"
    },
    {
      "_id": "665eb259642ec737898601c5",
      "userId": {
        "_id": "665c15ed4a75d84c758d6053",
        "fullName": "Mahidul Mandal",
        "referId": "RIL923981860"
      },
      "grand_total": 33084,
      "createdAt": "2024-06-04T06:21:13.749Z",
      "commision": 330.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.84000000000003"
    },
    {
      "_id": "665eb260642ec737898602d5",
      "userId": {
        "_id": "6659af7f4a75d84c757c214d",
        "fullName": "Sengkime M Sangma",
        "referId": "RIL658266031"
      },
      "grand_total": 17388,
      "createdAt": "2024-06-04T06:21:20.956Z",
      "commision": 695.52,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 347.76 and MARQUISE = 2% = 347.76"
    },
    {
      "_id": "665eb2b1642ec73789860b0e",
      "userId": {
        "_id": "665ea0d3642ec73789848084",
        "fullName": "Jaseng marak",
        "referId": "RIL988887274"
      },
      "grand_total": 41227,
      "createdAt": "2024-06-04T06:22:41.769Z",
      "commision": 1649.08,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 824.54 and MARQUISE = 2% = 824.54"
    },
    {
      "_id": "665eb2c9642ec73789860e20",
      "userId": {
        "_id": "6656c961965893d079529346",
        "fullName": "GEETA BRAHMA",
        "referId": "RIL356990450"
      },
      "grand_total": 8694,
      "createdAt": "2024-06-04T06:23:05.169Z",
      "commision": 86.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 86.94"
    },
    {
      "_id": "665f31c1642ec737898bbfd7",
      "userId": {
        "_id": "665eec7f642ec7378989537b",
        "fullName": "Harilal biswas",
        "referId": "RIL995104834"
      },
      "grand_total": 12641,
      "createdAt": "2024-06-04T15:24:49.240Z",
      "commision": 316.025,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 189.61499999999998 and MARQUISE = 1% = 126.41"
    },
    {
      "_id": "665ff949cc2b9b28595e50cd",
      "userId": {
        "_id": "665d8ab9f9d7a312a9564152",
        "fullName": "Rofiqul Islam",
        "referId": "RIL614353930"
      },
      "grand_total": 33131,
      "createdAt": "2024-06-05T05:36:09.552Z",
      "commision": 331.31,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.31"
    },
    {
      "_id": "66600976cc2b9b28595f2b5b",
      "userId": {
        "_id": "6650dbac832396d411513749",
        "referId": "RIL301379955",
        "fullName": "AZIBUL ALI"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-05T06:45:10.646Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66600982cc2b9b28595f2d0d",
      "userId": {
        "_id": "6659857d4a75d84c7578d50c",
        "fullName": "Hitesh Das",
        "referId": "RIL275511787"
      },
      "grand_total": 7095,
      "createdAt": "2024-06-05T06:45:22.150Z",
      "commision": 70.95,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 70.95"
    },
    {
      "_id": "66602214cc2b9b2859607a51",
      "userId": {
        "_id": "665ff9cacc2b9b28595e59b3",
        "fullName": "Bhargab Deka",
        "referId": "RIL837116919"
      },
      "grand_total": 33184,
      "createdAt": "2024-06-05T08:30:12.215Z",
      "commision": 497.76,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 497.76"
    },
    {
      "_id": "66602378cc2b9b2859608899",
      "userId": {
        "_id": "66600423cc2b9b28595edeea",
        "fullName": "Hedik R Sangma",
        "referId": "RIL552392606"
      },
      "grand_total": 8695,
      "createdAt": "2024-06-05T08:36:08.261Z",
      "commision": 347.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 173.9 and MARQUISE = 2% = 173.9"
    },
    {
      "_id": "666036b9cc2b9b285961626b",
      "userId": {
        "_id": "6650dbab832396d4115132ba",
        "referId": "RIL289504070",
        "fullName": "Jesmin  Begum"
      },
      "grand_total": 18090,
      "createdAt": "2024-06-05T09:58:17.164Z",
      "commision": 180.9,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 180.9"
    },
    {
      "_id": "666071d0cc2b9b2859629251",
      "userId": {
        "_id": "665ffaaacc2b9b28595e5f1b",
        "fullName": "Motaher ali",
        "referId": "RIL808729865"
      },
      "grand_total": 33133,
      "createdAt": "2024-06-05T14:10:24.115Z",
      "commision": 331.33,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.33"
    },
    {
      "_id": "666091a6cc2b9b2859636716",
      "userId": {
        "_id": "66607f90cc2b9b285962f161",
        "fullName": "Dhansing teron",
        "referId": "RIL488868861"
      },
      "grand_total": 12694,
      "createdAt": "2024-06-05T16:26:14.589Z",
      "commision": 190.41,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 190.41"
    },
    {
      "_id": "66609322cc2b9b2859636e55",
      "userId": {
        "_id": "66585128a6853c44449b6798",
        "fullName": "Kiren terang",
        "referId": "RIL971960055"
      },
      "grand_total": 10993,
      "createdAt": "2024-06-05T16:32:34.458Z",
      "commision": 164.89499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 164.89499999999998"
    },
    {
      "_id": "6661566bcc2b9b285966a8e8",
      "userId": {
        "_id": "665eabb9642ec73789855bc7",
        "fullName": "Gamrik R marak",
        "referId": "RIL637932498"
      },
      "grand_total": 40929,
      "createdAt": "2024-06-06T06:25:47.762Z",
      "commision": 1637.16,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 818.58 and MARQUISE = 2% = 818.58"
    },
    {
      "_id": "66615a87cc2b9b285966ec90",
      "userId": {
        "_id": "6650dbac832396d411513911",
        "referId": "RIL305985026",
        "fullName": "HAMIDUL ISLAM"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-06T06:43:19.397Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "66616c5dcc2b9b285967ef56",
      "userId": {
        "_id": "6661592acc2b9b285966db46",
        "fullName": "Biswajit",
        "referId": "RIL746062305"
      },
      "grand_total": 40631,
      "createdAt": "2024-06-06T07:59:25.220Z",
      "commision": 406.31,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 406.31"
    },
    {
      "_id": "66617ba1cc2b9b28596896d6",
      "userId": {
        "_id": "666151b1cc2b9b2859666dbf",
        "fullName": "Omar Faruk",
        "referId": "RIL420511947"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-06T09:04:33.527Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66617f66cc2b9b285968d74e",
      "userId": {
        "_id": "665ff121cc2b9b28595ddc79",
        "fullName": "Dakhar swargiary",
        "referId": "RIL535096270"
      },
      "grand_total": 17040,
      "createdAt": "2024-06-06T09:20:38.330Z",
      "commision": 170.4,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 170.4"
    },
    {
      "_id": "66618321cc2b9b2859691375",
      "userId": {
        "_id": "665ede32642ec73789888d22",
        "fullName": "Malati swargiary",
        "referId": "RIL557211542"
      },
      "grand_total": 8145,
      "createdAt": "2024-06-06T09:36:33.276Z",
      "commision": 81.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 81.45"
    },
    {
      "_id": "6661843fcc2b9b285969212f",
      "userId": {
        "_id": "6660095fcc2b9b28595f29c9",
        "fullName": "Mrityunjay Boro",
        "referId": "RIL339524106"
      },
      "grand_total": 32985,
      "createdAt": "2024-06-06T09:41:19.754Z",
      "commision": 329.85,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 329.85"
    },
    {
      "_id": "666184d9cc2b9b285969257b",
      "userId": {
        "_id": "6650dbac832396d4115136b7",
        "referId": "RIL299905730",
        "fullName": "Jitumoni Kalita"
      },
      "grand_total": 16142,
      "createdAt": "2024-06-06T09:43:53.241Z",
      "commision": 322.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 161.42000000000002 and MARQUISE = 1% = 161.42000000000002"
    },
    {
      "_id": "666195dbcc2b9b285969a26a",
      "userId": {
        "_id": "6650dbac832396d411513975",
        "referId": "RIL307018419",
        "fullName": "Sentu Das"
      },
      "grand_total": 8045,
      "createdAt": "2024-06-06T10:56:27.654Z",
      "commision": 80.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 80.45"
    },
    {
      "_id": "6661a06cef06c133c13a55ee",
      "userId": {
        "_id": "6650dbac832396d41151396c",
        "referId": "RIL306922884",
        "fullName": "Benard  R sangma"
      },
      "grand_total": 27587,
      "createdAt": "2024-06-06T11:41:32.959Z",
      "commision": 1103.48,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 551.74 and MARQUISE = 2% = 551.74"
    },
    {
      "_id": "6661acdeef06c133c13abed9",
      "userId": {
        "_id": "666157c6cc2b9b285966c282",
        "fullName": "Ajhar uddin",
        "referId": "RIL935639137"
      },
      "grand_total": 32734,
      "createdAt": "2024-06-06T12:34:38.989Z",
      "commision": 327.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.34000000000003"
    },
    {
      "_id": "6661c66c734b6c72d20050f0",
      "userId": {
        "_id": "665eb058642ec7378985d6e9",
        "fullName": "Robin narzary",
        "referId": "RIL732447874"
      },
      "grand_total": 20988,
      "createdAt": "2024-06-06T14:23:40.460Z",
      "commision": 314.82,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 314.82"
    },
    {
      "_id": "6661d33b734b6c72d200cc68",
      "userId": {
        "_id": "665af91e4a75d84c75898145",
        "fullName": "Bikash Timung",
        "referId": "RIL209638542"
      },
      "grand_total": 21988,
      "createdAt": "2024-06-06T15:18:19.408Z",
      "commision": 329.82,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 329.82"
    },
    {
      "_id": "666293c2734b6c72d20337a1",
      "userId": {
        "_id": "665ebe93642ec7378986d5a8",
        "fullName": "Semba",
        "referId": "RIL826249039"
      },
      "grand_total": 9494,
      "createdAt": "2024-06-07T04:59:46.197Z",
      "commision": 379.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 189.88 and MARQUISE = 2% = 189.88"
    },
    {
      "_id": "66629da3734b6c72d2038c32",
      "userId": {
        "_id": "6650dbac832396d41151339c",
        "referId": "RIL291752110",
        "fullName": "Imjamamul Hoque"
      },
      "grand_total": 6996,
      "createdAt": "2024-06-07T05:41:55.169Z",
      "commision": 69.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 69.96000000000001"
    },
    {
      "_id": "6662af4d734b6c72d2045a21",
      "userId": {
        "_id": "6661e18e734b6c72d20117d9",
        "fullName": "Om bahadur sunar",
        "referId": "RIL171494616"
      },
      "grand_total": 8095,
      "createdAt": "2024-06-07T06:57:17.020Z",
      "commision": 202.375,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 121.425 and MARQUISE = 1% = 80.95"
    },
    {
      "_id": "6662b565734b6c72d2048e05",
      "userId": {
        "_id": "665eec7f642ec7378989537b",
        "fullName": "Harilal biswas",
        "referId": "RIL995104834"
      },
      "grand_total": 34885,
      "createdAt": "2024-06-07T07:23:17.980Z",
      "commision": 872.125,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 523.275 and MARQUISE = 1% = 348.85"
    },
    {
      "_id": "6662b5bf734b6c72d2049252",
      "userId": {
        "_id": "66616745cc2b9b285967ac43",
        "fullName": "Mominul Hoque",
        "referId": "RIL195570903"
      },
      "grand_total": 17142,
      "createdAt": "2024-06-07T07:24:47.941Z",
      "commision": 171.42000000000002,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 171.42000000000002"
    },
    {
      "_id": "6662b9c5734b6c72d204c47b",
      "userId": {
        "_id": "6650dbac832396d41151359a",
        "referId": "RIL306055134",
        "fullName": "Abdul Khalek"
      },
      "grand_total": 25984,
      "createdAt": "2024-06-07T07:41:57.780Z",
      "commision": 259.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 259.84000000000003"
    },
    {
      "_id": "6662bad6734b6c72d204d374",
      "userId": {
        "_id": "665ac05b4a75d84c7587abd1",
        "fullName": "Tenul",
        "referId": "RIL294824784"
      },
      "grand_total": 32632,
      "createdAt": "2024-06-07T07:46:30.519Z",
      "commision": 1305.28,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 652.64 and MARQUISE = 2% = 652.64"
    },
    {
      "_id": "6662bdb8734b6c72d204ef92",
      "userId": {
        "_id": "666013b9cc2b9b28595fd135",
        "fullName": "Walchira A Sangma",
        "referId": "RIL100154424"
      },
      "grand_total": 32281,
      "createdAt": "2024-06-07T07:58:48.171Z",
      "commision": 1291.24,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 645.62 and MARQUISE = 2% = 645.62"
    },
    {
      "_id": "6662bf98734b6c72d20501fc",
      "userId": {
        "_id": "66603115cc2b9b2859612227",
        "fullName": "Asmi sonowal",
        "referId": "RIL953278121"
      },
      "grand_total": 14393,
      "createdAt": "2024-06-07T08:06:48.858Z",
      "commision": 359.825,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 215.89499999999998 and MARQUISE = 1% = 143.93"
    },
    {
      "_id": "6662bfd4734b6c72d2050519",
      "userId": {
        "_id": "66601207cc2b9b28595fb7f5",
        "fullName": "Rikmera A Sangma",
        "referId": "RIL262405527"
      },
      "grand_total": 8694,
      "createdAt": "2024-06-07T08:07:48.184Z",
      "commision": 347.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 173.88 and MARQUISE = 2% = 173.88"
    },
    {
      "_id": "6662ce85734b6c72d2058e8b",
      "userId": {
        "_id": "6662c112734b6c72d205129c",
        "fullName": "Ajay nunia",
        "referId": "RIL794124816"
      },
      "grand_total": 25438,
      "createdAt": "2024-06-07T09:10:29.026Z",
      "commision": 635.95,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 381.57 and MARQUISE = 1% = 254.38"
    },
    {
      "_id": "6662e08f734b6c72d20608ac",
      "userId": {
        "_id": "66629fcf734b6c72d203a76d",
        "fullName": "Dahunlang Pachiang",
        "referId": "RIL968208626"
      },
      "grand_total": 41329,
      "createdAt": "2024-06-07T10:27:27.134Z",
      "commision": 826.58,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 413.29 and MARQUISE = 1% = 413.29"
    },
    {
      "_id": "6662e77e734b6c72d2064647",
      "userId": {
        "_id": "665d6ac6f9d7a312a955469b",
        "fullName": "Aminul Haque",
        "referId": "RIL626799713"
      },
      "grand_total": 33034,
      "createdAt": "2024-06-07T10:57:02.979Z",
      "commision": 330.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.34000000000003"
    },
    {
      "_id": "6662f159734b6c72d2069998",
      "userId": {
        "_id": "6662b6d6734b6c72d204a56d",
        "fullName": "Faruk Ahmed",
        "referId": "RIL462959149"
      },
      "grand_total": 32783,
      "createdAt": "2024-06-07T11:39:05.155Z",
      "commision": 655.66,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.83 and MARQUISE = 1% = 327.83"
    },
    {
      "_id": "66630b1e734b6c72d2072c39",
      "userId": {
        "_id": "6661a994ef06c133c13aa5c7",
        "fullName": "Karibul Hoque",
        "referId": "RIL182426585"
      },
      "grand_total": 32835,
      "createdAt": "2024-06-07T13:29:02.228Z",
      "commision": 328.35,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.35"
    },
    {
      "_id": "666417a321ff1fd927207d8e",
      "userId": {
        "_id": "6662cee1734b6c72d20592d1",
        "fullName": "Rohit thapa",
        "referId": "RIL451968980"
      },
      "grand_total": 8095,
      "createdAt": "2024-06-08T08:34:43.167Z",
      "commision": 202.375,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 121.425 and MARQUISE = 1% = 80.95"
    },
    {
      "_id": "666419e821ff1fd92720ba6d",
      "userId": {
        "_id": "66603115cc2b9b2859612227",
        "fullName": "Asmi sonowal",
        "referId": "RIL953278121"
      },
      "grand_total": 17940,
      "createdAt": "2024-06-08T08:44:24.424Z",
      "commision": 448.5,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 269.09999999999997 and MARQUISE = 1% = 179.4"
    },
    {
      "_id": "6664333d21ff1fd92722f3b0",
      "userId": {
        "_id": "66603115cc2b9b2859612227",
        "fullName": "Asmi sonowal",
        "referId": "RIL953278121"
      },
      "grand_total": 8045,
      "createdAt": "2024-06-08T10:32:29.256Z",
      "commision": 201.125,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 120.675 and MARQUISE = 1% = 80.45"
    },
    {
      "_id": "66644832fdd7ecd406a56d95",
      "userId": {
        "_id": "6663da3f21ff1fd9271abc7a",
        "fullName": "Pritam Pegu",
        "referId": "RIL427721563"
      },
      "grand_total": 26137,
      "createdAt": "2024-06-08T12:01:54.607Z",
      "commision": 392.055,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 392.055"
    },
    {
      "_id": "6664579bfdd7ecd406a6c387",
      "userId": {
        "_id": "66607f90cc2b9b285962f161",
        "fullName": "Dhansing teron",
        "referId": "RIL488868861"
      },
      "grand_total": 12643,
      "createdAt": "2024-06-08T13:07:39.590Z",
      "commision": 189.64499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 189.64499999999998"
    },
    {
      "_id": "666457dffdd7ecd406a6cb08",
      "userId": {
        "_id": "66629f04734b6c72d203a493",
        "fullName": "Aminul islam",
        "referId": "RIL292556625"
      },
      "grand_total": 32783,
      "createdAt": "2024-06-08T13:08:47.768Z",
      "commision": 327.83,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.83"
    },
    {
      "_id": "66647a5bfdd7ecd406aaba08",
      "userId": {
        "_id": "6661699dcc2b9b285967c9bf",
        "fullName": "Rakibul Islam",
        "referId": "RIL937831409"
      },
      "grand_total": 41129,
      "createdAt": "2024-06-08T15:35:55.378Z",
      "commision": 411.29,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 411.29"
    },
    {
      "_id": "66652a014da6038aa2f6648d",
      "userId": {
        "_id": "665af91e4a75d84c75898145",
        "fullName": "Bikash Timung",
        "referId": "RIL209638542"
      },
      "grand_total": 6996,
      "createdAt": "2024-06-09T04:05:21.266Z",
      "commision": 104.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 104.94"
    },
    {
      "_id": "66653e4c4da6038aa2f755e2",
      "userId": {
        "_id": "66616116cc2b9b2859674f16",
        "fullName": "Shaiz Uddin",
        "referId": "RIL584702977"
      },
      "grand_total": 33085,
      "createdAt": "2024-06-09T05:31:56.671Z",
      "commision": 330.85,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.85"
    },
    {
      "_id": "66656e3330ac759e7fd766b9",
      "userId": {
        "_id": "6659833c4a75d84c7578a0af",
        "fullName": "Akkes Ali",
        "referId": "RIL348412318"
      },
      "grand_total": 33185,
      "createdAt": "2024-06-09T08:56:19.388Z",
      "commision": 331.85,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.85"
    },
    {
      "_id": "6665c95430ac759e7fda5673",
      "userId": {
        "_id": "66616745cc2b9b285967ac43",
        "fullName": "Mominul Hoque",
        "referId": "RIL195570903"
      },
      "grand_total": 14593,
      "createdAt": "2024-06-09T15:25:08.922Z",
      "commision": 145.93,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 145.93"
    },
    {
      "_id": "6665d56830ac759e7fda8ed8",
      "userId": {
        "_id": "6665b0e730ac759e7fd9cd34",
        "fullName": "Eyarul Rahman",
        "referId": "RIL616306179"
      },
      "grand_total": 32781,
      "createdAt": "2024-06-09T16:16:40.609Z",
      "commision": 327.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.81"
    },
    {
      "_id": "6666988730ac759e7fdd550f",
      "userId": {
        "_id": "6665524d4da6038aa2f874dd",
        "fullName": "AMISHA GOYARY",
        "referId": "RIL223035284"
      },
      "grand_total": 33181,
      "createdAt": "2024-06-10T06:09:11.400Z",
      "commision": 331.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.81"
    },
    {
      "_id": "666699f130ac759e7fdd6c6c",
      "userId": {
        "_id": "6650dbac832396d411513970",
        "referId": "RIL306969425",
        "fullName": "Rakasal Momin"
      },
      "grand_total": 32036,
      "createdAt": "2024-06-10T06:15:13.414Z",
      "commision": 1281.44,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 640.72 and MARQUISE = 2% = 640.72"
    },
    {
      "_id": "66669a7d30ac759e7fdd74f9",
      "userId": {
        "_id": "66601207cc2b9b28595fb7f5",
        "fullName": "Rikmera A Sangma",
        "referId": "RIL262405527"
      },
      "grand_total": 17141,
      "createdAt": "2024-06-10T06:17:33.323Z",
      "commision": 685.64,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 342.82 and MARQUISE = 2% = 342.82"
    },
    {
      "_id": "6666b0ec30ac759e7fde9349",
      "userId": {
        "_id": "6665855a30ac759e7fd893ad",
        "fullName": "Soleman Siddique",
        "referId": "RIL536214416"
      },
      "grand_total": 33179,
      "createdAt": "2024-06-10T07:53:16.801Z",
      "commision": 331.79,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.79"
    },
    {
      "_id": "6666bf0830ac759e7fdf3f5d",
      "userId": {
        "_id": "6666935430ac759e7fdcf8de",
        "fullName": "Malati Bardewa",
        "referId": "RIL277561470"
      },
      "grand_total": 30432,
      "createdAt": "2024-06-10T08:53:28.750Z",
      "commision": 456.47999999999996,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 456.47999999999996"
    },
    {
      "_id": "6666c1f330ac759e7fdf66f1",
      "userId": {
        "_id": "6666935430ac759e7fdcf8de",
        "fullName": "Malati Bardewa",
        "referId": "RIL277561470"
      },
      "grand_total": 2199,
      "createdAt": "2024-06-10T09:05:55.843Z",
      "commision": 32.985,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 32.985"
    },
    {
      "_id": "6666c50a30ac759e7fdf888b",
      "userId": {
        "_id": "6666a06030ac759e7fddbe73",
        "fullName": "Ahinur islam",
        "referId": "RIL902709213"
      },
      "grand_total": 32783,
      "createdAt": "2024-06-10T09:19:06.702Z",
      "commision": 655.66,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.83 and MARQUISE = 1% = 327.83"
    },
    {
      "_id": "6666d10530ac759e7fe00ffc",
      "userId": {
        "_id": "66657c8730ac759e7fd8232c",
        "fullName": "Majharul islam talukder",
        "referId": "RIL540733925"
      },
      "grand_total": 21239,
      "createdAt": "2024-06-10T10:10:13.944Z",
      "commision": 212.39000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 212.39000000000001"
    },
    {
      "_id": "6666d1c630ac759e7fe018a0",
      "userId": {
        "_id": "6665785c30ac759e7fd7f387",
        "fullName": "Roselin Rajput",
        "referId": "RIL276624682"
      },
      "grand_total": 20439,
      "createdAt": "2024-06-10T10:13:26.022Z",
      "commision": 204.39000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 204.39000000000001"
    },
    {
      "_id": "6666d6c130ac759e7fe05af2",
      "userId": {
        "_id": "66616745cc2b9b285967ac43",
        "fullName": "Mominul Hoque",
        "referId": "RIL195570903"
      },
      "grand_total": 8743,
      "createdAt": "2024-06-10T10:34:41.007Z",
      "commision": 87.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 87.43"
    },
    {
      "_id": "6667123830ac759e7fe26afa",
      "userId": {
        "_id": "66657e6730ac759e7fd83a7e",
        "fullName": "LAZARUSH TIRKY",
        "referId": "RIL922432892"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-10T14:48:24.682Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "666715d530ac759e7fe291b0",
      "userId": {
        "_id": "666185accc2b9b2859692ce2",
        "fullName": "Dilip mandal",
        "referId": "RIL754824727"
      },
      "grand_total": 41180,
      "createdAt": "2024-06-10T15:03:49.507Z",
      "commision": 411.8,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 411.8"
    },
    {
      "_id": "6667237430ac759e7fe33892",
      "userId": {
        "_id": "6666a34830ac759e7fdde973",
        "fullName": "Dewan Ch Marak",
        "referId": "RIL409488015"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-10T16:01:56.119Z",
      "commision": 329.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 164.9 and MARQUISE = 2% = 164.9"
    },
    {
      "_id": "6667eacc30ac759e7fe90b43",
      "userId": {
        "_id": "666698ad30ac759e7fdd5711",
        "fullName": "Julhas Ali",
        "referId": "RIL528286231"
      },
      "grand_total": 32783,
      "createdAt": "2024-06-11T06:12:28.065Z",
      "commision": 327.83,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.83"
    },
    {
      "_id": "6667f0f130ac759e7fe961d7",
      "userId": {
        "_id": "6650dbac832396d411513568",
        "referId": "RIL29651474",
        "fullName": "Hiranya kumar  Gogoi"
      },
      "grand_total": 5796,
      "createdAt": "2024-06-11T06:38:41.208Z",
      "commision": 144.9,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 86.94 and MARQUISE = 1% = 57.96"
    },
    {
      "_id": "6667fe0530ac759e7fea27ea",
      "userId": {
        "_id": "6667f39330ac759e7fe990f1",
        "fullName": "Horendeep gogoi",
        "referId": "RIL270357672"
      },
      "grand_total": 40380,
      "createdAt": "2024-06-11T07:34:29.014Z",
      "commision": 1009.5,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 605.6999999999999 and MARQUISE = 1% = 403.8"
    },
    {
      "_id": "6668074130ac759e7fea8917",
      "userId": {
        "_id": "6667f5b730ac759e7fe9b954",
        "fullName": "Kellojing Ch Marak",
        "referId": "RIL623891466"
      },
      "grand_total": 8494,
      "createdAt": "2024-06-11T08:13:53.310Z",
      "commision": 339.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 169.88 and MARQUISE = 2% = 169.88"
    },
    {
      "_id": "666807b630ac759e7fea8fe5",
      "userId": {
        "_id": "6650dbac832396d4115136ee",
        "referId": "RIL300454880",
        "fullName": "Barak M marak"
      },
      "grand_total": 5993,
      "createdAt": "2024-06-11T08:15:50.814Z",
      "commision": 239.72,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 119.86 and MARQUISE = 2% = 119.86"
    },
    {
      "_id": "6668133b30ac759e7feb0c4c",
      "userId": {
        "_id": "6667e20a30ac759e7fe8924d",
        "fullName": "Martin Sitlhou",
        "referId": "RIL125846912"
      },
      "grand_total": 16141,
      "createdAt": "2024-06-11T09:04:59.195Z",
      "commision": 242.11499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 242.11499999999998"
    },
    {
      "_id": "66681e8430ac759e7febaf0f",
      "userId": {
        "_id": "666817e130ac759e7feb4bca",
        "fullName": "Niranjan Hajong",
        "referId": "RIL439708932"
      },
      "grand_total": 16541,
      "createdAt": "2024-06-11T09:53:08.084Z",
      "commision": 165.41,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 165.41"
    },
    {
      "_id": "666832c730ac759e7fec9cad",
      "userId": {
        "_id": "666821b530ac759e7febdd4b",
        "fullName": "royal Basumatary",
        "referId": "RIL998487613"
      },
      "grand_total": 7996,
      "createdAt": "2024-06-11T11:19:35.873Z",
      "commision": 119.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 119.94"
    },
    {
      "_id": "6668392930ac759e7fecccf1",
      "userId": {
        "_id": "6657fc89d146ae35e218e1dc",
        "fullName": "Gersonsoma Ngamlai",
        "referId": "RIL386362710"
      },
      "grand_total": 32782,
      "createdAt": "2024-06-11T11:46:49.464Z",
      "commision": 655.64,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.82 and MARQUISE = 1% = 327.82"
    },
    {
      "_id": "66683d2530ac759e7feceff1",
      "userId": {
        "_id": "666806a430ac759e7fea8631",
        "fullName": "Nayan Saikia",
        "referId": "RIL798760290"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-11T12:03:49.699Z",
      "commision": 577.15,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 164.9 and MARQUISE = 5% = 412.25"
    },
    {
      "_id": "6669131733b5f38b970f6914",
      "userId": {
        "_id": "666523c24da6038aa2f6172b",
        "fullName": "Jwngsar basumatary",
        "referId": "RIL869921774"
      },
      "grand_total": 15593,
      "createdAt": "2024-06-12T03:16:39.943Z",
      "commision": 233.89499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 233.89499999999998"
    },
    {
      "_id": "6669159733b5f38b970f7f7f",
      "userId": {
        "_id": "666523c24da6038aa2f6172b",
        "fullName": "Jwngsar basumatary",
        "referId": "RIL869921774"
      },
      "grand_total": 13595,
      "createdAt": "2024-06-12T03:27:19.427Z",
      "commision": 203.92499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 203.92499999999998"
    },
    {
      "_id": "66691ee633b5f38b970fe32e",
      "userId": {
        "_id": "6667f9dd30ac759e7fe9e525",
        "fullName": "Wilchi",
        "referId": "RIL622931431"
      },
      "grand_total": 28480,
      "createdAt": "2024-06-12T04:07:02.596Z",
      "commision": 1139.2,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 569.6 and MARQUISE = 2% = 569.6"
    },
    {
      "_id": "66692d6233b5f38b97108f7b",
      "userId": {
        "_id": "6650dbac832396d4115135b0",
        "referId": "RIL297254964",
        "fullName": "Jesmina Parbin"
      },
      "grand_total": 10893,
      "createdAt": "2024-06-12T05:08:50.416Z",
      "commision": 108.93,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 108.93"
    },
    {
      "_id": "66693d2433b5f38b971153d4",
      "userId": {
        "_id": "666401a221ff1fd9271e88c2",
        "fullName": "Chiang Ch Marak",
        "referId": "RIL351791847"
      },
      "grand_total": 32782,
      "createdAt": "2024-06-12T06:16:04.865Z",
      "commision": 1311.28,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 655.64 and MARQUISE = 2% = 655.64"
    },
    {
      "_id": "6669486d33b5f38b9712080c",
      "userId": {
        "_id": "66693e2b33b5f38b971164ce",
        "fullName": "Frangsngi D Sangma",
        "referId": "RIL384171569"
      },
      "grand_total": 17040,
      "createdAt": "2024-06-12T07:04:13.066Z",
      "commision": 681.6,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 340.8 and MARQUISE = 2% = 340.8"
    },
    {
      "_id": "66694bde33b5f38b971246c5",
      "userId": {
        "_id": "6669354133b5f38b9710ed98",
        "fullName": "Kamsanggin Lunkim",
        "referId": "RIL865438522"
      },
      "grand_total": 33129,
      "createdAt": "2024-06-12T07:18:54.560Z",
      "commision": 496.935,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 496.935"
    },
    {
      "_id": "666969b033b5f38b97140212",
      "userId": {
        "_id": "66693f6d33b5f38b971179ea",
        "fullName": "Rahul Das",
        "referId": "RIL734939533"
      },
      "grand_total": 8195,
      "createdAt": "2024-06-12T09:26:08.808Z",
      "commision": 81.95,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 81.95"
    },
    {
      "_id": "666a7bc333b5f38b971a57ea",
      "userId": {
        "_id": "6669202533b5f38b970ff36e",
        "fullName": "Robiyal shek",
        "referId": "RIL404361517"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-13T04:55:31.801Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "666a7cec33b5f38b971a6659",
      "userId": {
        "_id": "666a75a333b5f38b971a053e",
        "fullName": "Jeuti Das",
        "referId": "RIL426639419"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-13T05:00:28.216Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "666a857c33b5f38b971b4383",
      "userId": {
        "_id": "6669869b33b5f38b97150928",
        "fullName": "Joushrang Narzary",
        "referId": "RIL888714195"
      },
      "grand_total": 32784,
      "createdAt": "2024-06-13T05:37:00.834Z",
      "commision": 327.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.84000000000003"
    },
    {
      "_id": "666a863833b5f38b971b56aa",
      "userId": {
        "_id": "6659745b4a75d84c75774f16",
        "fullName": "Pitan Biswas",
        "referId": "RIL597239811"
      },
      "grand_total": 32732,
      "createdAt": "2024-06-13T05:40:08.567Z",
      "commision": 327.32,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.32"
    },
    {
      "_id": "666a907e33b5f38b971bfe91",
      "userId": {
        "_id": "666a731d33b5f38b9719e898",
        "fullName": "Amazing Grace Muksor",
        "referId": "RIL329845661"
      },
      "grand_total": 8345,
      "createdAt": "2024-06-13T06:23:58.939Z",
      "commision": 166.9,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 83.45 and MARQUISE = 1% = 83.45"
    },
    {
      "_id": "666a928933b5f38b971c1e4f",
      "userId": {
        "_id": "6665785c30ac759e7fd7f387",
        "fullName": "Roselin Rajput",
        "referId": "RIL276624682"
      },
      "grand_total": 12243,
      "createdAt": "2024-06-13T06:32:41.082Z",
      "commision": 122.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 122.43"
    },
    {
      "_id": "666a999733b5f38b971c87a2",
      "userId": {
        "_id": "6650dbac832396d411513513",
        "referId": "RIL295651810",
        "fullName": "SHONJAB ALI"
      },
      "grand_total": 8494,
      "createdAt": "2024-06-13T07:02:47.108Z",
      "commision": 84.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 84.94"
    },
    {
      "_id": "666a9c7133b5f38b971cbced",
      "userId": {
        "_id": "666092efcc2b9b2859636d44",
        "fullName": "Jugananda Konch",
        "referId": "RIL112641757"
      },
      "grand_total": 40579,
      "createdAt": "2024-06-13T07:14:57.292Z",
      "commision": 1217.3700000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 405.79 and MARQUISE = 2% = 811.58"
    },
    {
      "_id": "666a9de333b5f38b971cdab0",
      "userId": {
        "_id": "665ebe93642ec7378986d5a8",
        "fullName": "Semba",
        "referId": "RIL826249039"
      },
      "grand_total": 8643,
      "createdAt": "2024-06-13T07:21:07.979Z",
      "commision": 345.72,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 172.86 and MARQUISE = 2% = 172.86"
    },
    {
      "_id": "666a9e6f33b5f38b971ce28d",
      "userId": {
        "_id": "666a883833b5f38b971b7f87",
        "fullName": "Khoda yania",
        "referId": "RIL105889643"
      },
      "grand_total": 40829,
      "createdAt": "2024-06-13T07:23:27.725Z",
      "commision": 816.58,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 408.29 and MARQUISE = 1% = 408.29"
    },
    {
      "_id": "666aabec33b5f38b971d8338",
      "userId": {
        "_id": "666aa26e33b5f38b971d26fa",
        "fullName": "Sanjib konch",
        "referId": "RIL747603887"
      },
      "grand_total": 32684,
      "createdAt": "2024-06-13T08:21:00.721Z",
      "commision": 980.5200000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 326.84000000000003 and MARQUISE = 2% = 653.6800000000001"
    },
    {
      "_id": "666aca3e33b5f38b971ef846",
      "userId": {
        "_id": "6669656e33b5f38b9713bc03",
        "fullName": "Jolly Diphusa",
        "referId": "RIL990396119"
      },
      "grand_total": 32732,
      "createdAt": "2024-06-13T10:30:22.744Z",
      "commision": 327.32,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.32"
    },
    {
      "_id": "666ad4ee33b5f38b971f676e",
      "userId": {
        "_id": "6663da3f21ff1fd9271abc7a",
        "fullName": "Pritam Pegu",
        "referId": "RIL427721563"
      },
      "grand_total": 6996,
      "createdAt": "2024-06-13T11:15:58.037Z",
      "commision": 104.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 104.94"
    },
    {
      "_id": "666ae63b33b5f38b9720756e",
      "userId": {
        "_id": "666ad19133b5f38b971f392c",
        "fullName": "Purnima Basumatary",
        "referId": "RIL938754115"
      },
      "grand_total": 32785,
      "createdAt": "2024-06-13T12:29:47.764Z",
      "commision": 327.85,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.85"
    },
    {
      "_id": "666aee8133b5f38b97210d70",
      "userId": {
        "_id": "66607f90cc2b9b285962f161",
        "fullName": "Dhansing teron",
        "referId": "RIL488868861"
      },
      "grand_total": 14691,
      "createdAt": "2024-06-13T13:05:05.623Z",
      "commision": 220.36499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 220.36499999999998"
    },
    {
      "_id": "666afdb233b5f38b97220817",
      "userId": {
        "_id": "66607f90cc2b9b285962f161",
        "fullName": "Dhansing teron",
        "referId": "RIL488868861"
      },
      "grand_total": 249,
      "createdAt": "2024-06-13T14:09:54.474Z",
      "commision": 3.735,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 3.735"
    },
    {
      "_id": "666bc38f33b5f38b97255f16",
      "userId": {
        "_id": "665c56304a75d84c758eb474",
        "fullName": "Manek Basumatary",
        "referId": "RIL977746443"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-14T04:14:07.419Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "666bc3b233b5f38b972561e7",
      "userId": {
        "_id": "666553d84da6038aa2f88620",
        "fullName": "Santanu bhuyan",
        "referId": "RIL797254309"
      },
      "grand_total": 40278,
      "createdAt": "2024-06-14T04:14:42.682Z",
      "commision": 402.78000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 402.78000000000003"
    },
    {
      "_id": "666bc9d233b5f38b9725abe1",
      "userId": {
        "_id": "6650dbac832396d411513832",
        "referId": "RIL303748726",
        "fullName": "Silaming  K Sangma"
      },
      "grand_total": 12791,
      "createdAt": "2024-06-14T04:40:50.456Z",
      "commision": 511.64,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 255.82 and MARQUISE = 2% = 255.82"
    },
    {
      "_id": "666bd3b433b5f38b972634d4",
      "userId": {
        "_id": "666bc77233b5f38b97259046",
        "fullName": "Bishnu doley",
        "referId": "RIL370457583"
      },
      "grand_total": 32884,
      "createdAt": "2024-06-14T05:23:00.235Z",
      "commision": 493.26,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 493.26"
    },
    {
      "_id": "666bd81733b5f38b9726671c",
      "userId": {
        "_id": "66696f5933b5f38b9714339e",
        "fullName": "Tapan Daimary",
        "referId": "RIL599935352"
      },
      "grand_total": 29685,
      "createdAt": "2024-06-14T05:41:43.856Z",
      "commision": 445.275,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 445.275"
    },
    {
      "_id": "666be08c33b5f38b9726da58",
      "userId": {
        "_id": "6659af7f4a75d84c757c214d",
        "fullName": "Sengkime M Sangma",
        "referId": "RIL658266031"
      },
      "grand_total": 8795,
      "createdAt": "2024-06-14T06:17:48.249Z",
      "commision": 351.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 175.9 and MARQUISE = 2% = 175.9"
    },
    {
      "_id": "666be43833b5f38b97270519",
      "userId": {
        "_id": "666013b9cc2b9b28595fd135",
        "fullName": "Walchira A Sangma",
        "referId": "RIL100154424"
      },
      "grand_total": 8595,
      "createdAt": "2024-06-14T06:33:28.599Z",
      "commision": 343.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 171.9 and MARQUISE = 2% = 171.9"
    },
    {
      "_id": "666be57a33b5f38b97271ca0",
      "userId": {
        "_id": "66601207cc2b9b28595fb7f5",
        "fullName": "Rikmera A Sangma",
        "referId": "RIL262405527"
      },
      "grand_total": 14542,
      "createdAt": "2024-06-14T06:38:50.144Z",
      "commision": 581.6800000000001,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 290.84000000000003 and MARQUISE = 2% = 290.84000000000003"
    },
    {
      "_id": "666beaad33b5f38b97276e50",
      "userId": {
        "_id": "665ff121cc2b9b28595ddc79",
        "fullName": "Dakhar swargiary",
        "referId": "RIL535096270"
      },
      "grand_total": 15192,
      "createdAt": "2024-06-14T07:01:01.140Z",
      "commision": 151.92000000000002,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 151.92000000000002"
    },
    {
      "_id": "666beac333b5f38b9727708c",
      "userId": {
        "_id": "665d6ee1f9d7a312a9556149",
        "fullName": "Jagadish",
        "referId": "RIL238174488"
      },
      "grand_total": 40781,
      "createdAt": "2024-06-14T07:01:23.889Z",
      "commision": 407.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 407.81"
    },
    {
      "_id": "666bedd133b5f38b97279c37",
      "userId": {
        "_id": "6669701d33b5f38b97143e3f",
        "fullName": "Birkhang mushahary",
        "referId": "RIL418627283"
      },
      "grand_total": 17191,
      "createdAt": "2024-06-14T07:14:25.167Z",
      "commision": 171.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 171.91"
    },
    {
      "_id": "666bef4c33b5f38b9727b0ac",
      "userId": {
        "_id": "6667f9dd30ac759e7fe9e525",
        "fullName": "Wilchi",
        "referId": "RIL622931431"
      },
      "grand_total": 4196,
      "createdAt": "2024-06-14T07:20:44.838Z",
      "commision": 167.84,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 83.92 and MARQUISE = 2% = 83.92"
    },
    {
      "_id": "666bf14633b5f38b9727cbe4",
      "userId": {
        "_id": "66698e1833b5f38b971541ea",
        "fullName": "Biswajit Barman",
        "referId": "RIL440019221"
      },
      "grand_total": 8695,
      "createdAt": "2024-06-14T07:29:10.919Z",
      "commision": 86.95,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 86.95"
    },
    {
      "_id": "666c03d033b5f38b9728b91f",
      "userId": {
        "_id": "666523c24da6038aa2f6172b",
        "fullName": "Jwngsar basumatary",
        "referId": "RIL869921774"
      },
      "grand_total": 10394,
      "createdAt": "2024-06-14T08:48:16.078Z",
      "commision": 155.91,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 155.91"
    },
    {
      "_id": "666c2ada33b5f38b972a1878",
      "userId": {
        "_id": "665eb058642ec7378985d6e9",
        "fullName": "Robin narzary",
        "referId": "RIL732447874"
      },
      "grand_total": 11594,
      "createdAt": "2024-06-14T11:34:50.134Z",
      "commision": 173.91,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 173.91"
    },
    {
      "_id": "666c31b033b5f38b972a5103",
      "userId": {
        "_id": "666c204b33b5f38b9729c06b",
        "fullName": "someswar narzary",
        "referId": "RIL213664854"
      },
      "grand_total": 16792,
      "createdAt": "2024-06-14T12:04:00.171Z",
      "commision": 251.88,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 251.88"
    },
    {
      "_id": "666c4b6b33b5f38b972aeea3",
      "userId": {
        "_id": "666821b530ac759e7febdd4b",
        "fullName": "royal Basumatary",
        "referId": "RIL998487613"
      },
      "grand_total": 23886,
      "createdAt": "2024-06-14T13:53:47.335Z",
      "commision": 358.28999999999996,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 358.28999999999996"
    },
    {
      "_id": "666c4c1733b5f38b972af46e",
      "userId": {
        "_id": "666821b530ac759e7febdd4b",
        "fullName": "royal Basumatary",
        "referId": "RIL998487613"
      },
      "grand_total": 399,
      "createdAt": "2024-06-14T13:56:39.146Z",
      "commision": 5.984999999999999,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 5.984999999999999"
    },
    {
      "_id": "666c7fbd33b5f38b972c2734",
      "userId": {
        "_id": "666c06f133b5f38b9728dd69",
        "fullName": "Rezaul korim",
        "referId": "RIL420390037"
      },
      "grand_total": 32783,
      "createdAt": "2024-06-14T17:37:01.192Z",
      "commision": 655.66,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.83 and MARQUISE = 1% = 327.83"
    },
    {
      "_id": "666d51f171047f867491ad9c",
      "userId": {
        "_id": "666d204e71047f8674901e4c",
        "fullName": "Thilkunga Thianglai",
        "referId": "RIL458144875"
      },
      "grand_total": 32536,
      "createdAt": "2024-06-15T08:33:53.296Z",
      "commision": 650.72,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 325.36 and MARQUISE = 1% = 325.36"
    },
    {
      "_id": "666d530d71047f867491bc31",
      "userId": {
        "_id": "66614d38cc2b9b2859664e9f",
        "fullName": "Facillity Syih",
        "referId": "RIL809495262"
      },
      "grand_total": 17091,
      "createdAt": "2024-06-15T08:38:37.660Z",
      "commision": 341.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 170.91 and MARQUISE = 1% = 170.91"
    },
    {
      "_id": "666d606171047f86749228fa",
      "userId": {
        "_id": "666d548371047f867491ca38",
        "fullName": "KRISHNA BORUAH",
        "referId": "RIL469669566"
      },
      "grand_total": 17142,
      "createdAt": "2024-06-15T09:35:29.100Z",
      "commision": 428.55,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 257.13 and MARQUISE = 1% = 171.42000000000002"
    },
    {
      "_id": "666d608671047f8674922b41",
      "userId": {
        "_id": "666d569971047f867491da6d",
        "fullName": "Satyajit Rabha",
        "referId": "RIL801661418"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-15T09:36:06.806Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "666d683971047f8674926815",
      "userId": {
        "_id": "666d4bd371047f8674916313",
        "fullName": "Suneswar Boro",
        "referId": "RIL304800487"
      },
      "grand_total": 32734,
      "createdAt": "2024-06-15T10:08:57.381Z",
      "commision": 327.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.34000000000003"
    },
    {
      "_id": "666d965971047f867493457e",
      "userId": {
        "_id": "6650dbac832396d411513691",
        "referId": "RIL299505249",
        "fullName": "Chenya Momin"
      },
      "grand_total": 8689,
      "createdAt": "2024-06-15T13:25:45.535Z",
      "commision": 347.56,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 173.78 and MARQUISE = 2% = 173.78"
    },
    {
      "_id": "666d9ee371047f8674938896",
      "userId": {
        "_id": "666d071571047f86748f7e23",
        "fullName": "Tangmera",
        "referId": "RIL402600358"
      },
      "grand_total": 17089,
      "createdAt": "2024-06-15T14:02:11.864Z",
      "commision": 683.5600000000001,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 341.78000000000003 and MARQUISE = 2% = 341.78000000000003"
    },
    {
      "_id": "666da52c71047f867493afcf",
      "userId": {
        "_id": "666d95eb71047f867493415f",
        "fullName": "Bringwill Sangma",
        "referId": "RIL946983675"
      },
      "grand_total": 16890,
      "createdAt": "2024-06-15T14:29:00.426Z",
      "commision": 1689,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 337.8 and MARQUISE = 8% = 1351.2"
    },
    {
      "_id": "666db16671047f867493edfa",
      "userId": {
        "_id": "6668187c30ac759e7feb56dd",
        "fullName": "Amar kumar shil",
        "referId": "RIL355741514"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-15T15:21:10.347Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "666dceb471047f86749462b8",
      "userId": {
        "_id": "666d484b71047f8674914535",
        "fullName": "𝐑𝐚𝐤𝐬𝐢𝐥 𝐃 𝐒𝐚𝐧𝐠𝐦𝐚",
        "referId": "RIL383793179"
      },
      "grand_total": 8592,
      "createdAt": "2024-06-15T17:26:12.933Z",
      "commision": 343.68,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 171.84 and MARQUISE = 2% = 171.84"
    },
    {
      "_id": "666e55ac71047f867494d5f1",
      "userId": {
        "_id": "666d454471047f8674912450",
        "fullName": "Sugrib mandal",
        "referId": "RIL618020048"
      },
      "grand_total": 32781,
      "createdAt": "2024-06-16T03:02:04.472Z",
      "commision": 327.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.81"
    },
    {
      "_id": "666e99ff71047f8674969301",
      "userId": {
        "_id": "66617493cc2b9b2859684f5e",
        "fullName": "Bishal Orang",
        "referId": "RIL190211045"
      },
      "grand_total": 9294,
      "createdAt": "2024-06-16T07:53:35.922Z",
      "commision": 185.88,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 92.94 and MARQUISE = 1% = 92.94"
    },
    {
      "_id": "666e9ace71047f8674969a1c",
      "userId": {
        "_id": "6662c112734b6c72d205129c",
        "fullName": "Ajay nunia",
        "referId": "RIL794124816"
      },
      "grand_total": 15092,
      "createdAt": "2024-06-16T07:57:02.415Z",
      "commision": 377.3,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 226.38 and MARQUISE = 1% = 150.92000000000002"
    },
    {
      "_id": "666ea1c471047f867496c777",
      "userId": {
        "_id": "6650dbaa832396d411512916",
        "referId": "RIL264577160",
        "fullName": "Robinson Ronghang"
      },
      "grand_total": 10942,
      "createdAt": "2024-06-16T08:26:44.327Z",
      "commision": 109.42,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 109.42"
    },
    {
      "_id": "666ee2fd71047f8674981283",
      "userId": {
        "_id": "666c204b33b5f38b9729c06b",
        "fullName": "someswar narzary",
        "referId": "RIL213664854"
      },
      "grand_total": 15694,
      "createdAt": "2024-06-16T13:05:01.554Z",
      "commision": 235.41,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 235.41"
    },
    {
      "_id": "666f06b371047f8674989a47",
      "userId": {
        "_id": "666ee8bb71047f8674982157",
        "fullName": "Prodipson marak",
        "referId": "RIL108598460"
      },
      "grand_total": 29686,
      "createdAt": "2024-06-16T15:37:23.662Z",
      "commision": 1187.44,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 593.72 and MARQUISE = 2% = 593.72"
    },
    {
      "_id": "666f1dd671047f8674991489",
      "userId": {
        "_id": "666e8d9871047f867496388d",
        "fullName": "Samarjit das",
        "referId": "RIL426124688"
      },
      "grand_total": 13194,
      "createdAt": "2024-06-16T17:16:06.517Z",
      "commision": 197.91,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 197.91"
    },
    {
      "_id": "666f21c971047f8674992790",
      "userId": {
        "_id": "666db4f571047f867493fbc3",
        "fullName": "Boisnabi Paik",
        "referId": "RIL865211501"
      },
      "grand_total": 32934,
      "createdAt": "2024-06-16T17:32:57.977Z",
      "commision": 658.6800000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 329.34000000000003 and MARQUISE = 1% = 329.34000000000003"
    },
    {
      "_id": "666f222271047f8674992a27",
      "userId": {
        "_id": "6662da5d734b6c72d205ced9",
        "fullName": "Himashri Deka",
        "referId": "RIL765167493"
      },
      "grand_total": 15241,
      "createdAt": "2024-06-16T17:34:26.937Z",
      "commision": 381.025,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 228.61499999999998 and MARQUISE = 1% = 152.41"
    },
    {
      "_id": "666f261271047f86749933fe",
      "userId": {
        "_id": "666dc1e571047f8674942cc2",
        "fullName": "Deepanjali Bhumij",
        "referId": "RIL769774541"
      },
      "grand_total": 13593,
      "createdAt": "2024-06-16T17:51:14.355Z",
      "commision": 271.86,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 135.93 and MARQUISE = 1% = 135.93"
    },
    {
      "_id": "666faeac71047f8674999007",
      "userId": {
        "_id": "666eee7b71047f8674982db9",
        "fullName": "Chiranjit Patir",
        "referId": "RIL779918500"
      },
      "grand_total": 16792,
      "createdAt": "2024-06-17T03:34:04.116Z",
      "commision": 251.88,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 251.88"
    },
    {
      "_id": "666fc31671047f867499fd6a",
      "userId": {
        "_id": "66616876cc2b9b285967ba22",
        "fullName": "Mantu boro",
        "referId": "RIL676217771"
      },
      "grand_total": 4196,
      "createdAt": "2024-06-17T05:01:10.264Z",
      "commision": 41.96,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 41.96"
    },
    {
      "_id": "666fd6b971047f86749a8011",
      "userId": {
        "_id": "666d6ea871047f8674928f13",
        "fullName": "Bikash Das",
        "referId": "RIL181348331"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-17T06:24:57.086Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "666fd89b71047f86749a9194",
      "userId": {
        "_id": "666fd3b771047f86749a67ab",
        "fullName": "Badal nunia",
        "referId": "RIL851355161"
      },
      "grand_total": 21490,
      "createdAt": "2024-06-17T06:32:59.078Z",
      "commision": 537.25,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 322.34999999999997 and MARQUISE = 1% = 214.9"
    },
    {
      "_id": "666fe05571047f86749abf8c",
      "userId": {
        "_id": "666fc38171047f86749a009d",
        "fullName": "Lhinghoilhai Kholhou",
        "referId": "RIL389944378"
      },
      "grand_total": 23736,
      "createdAt": "2024-06-17T07:05:57.062Z",
      "commision": 356.03999999999996,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 356.03999999999996"
    },
    {
      "_id": "666fe46a71047f86749aec6c",
      "userId": {
        "_id": "6650dbac832396d41151393f",
        "referId": "RIL306472961",
        "fullName": "Hatmin Sangma"
      },
      "grand_total": 4345,
      "createdAt": "2024-06-17T07:23:22.090Z",
      "commision": 173.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 86.9 and MARQUISE = 2% = 86.9"
    },
    {
      "_id": "666fe5ed71047f86749b050a",
      "userId": {
        "_id": "666fe0bc71047f86749ac641",
        "fullName": "Atin Rabha",
        "referId": "RIL563663556"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-17T07:29:49.424Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "666ff00071047f86749b8662",
      "userId": {
        "_id": "666fdd9d71047f86749aaddc",
        "fullName": "Mayashri Deori",
        "referId": "RIL918109453"
      },
      "grand_total": 15290,
      "createdAt": "2024-06-17T08:12:48.748Z",
      "commision": 229.35,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 229.35"
    },
    {
      "_id": "666ff18f71047f86749b98c1",
      "userId": {
        "_id": "666523c24da6038aa2f6172b",
        "fullName": "Jwngsar basumatary",
        "referId": "RIL869921774"
      },
      "grand_total": 399,
      "createdAt": "2024-06-17T08:19:27.303Z",
      "commision": 5.984999999999999,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 5.984999999999999"
    },
    {
      "_id": "666ff8ef71047f86749bd6af",
      "userId": {
        "_id": "6659af7f4a75d84c757c214d",
        "fullName": "Sengkime M Sangma",
        "referId": "RIL658266031"
      },
      "grand_total": 6144,
      "createdAt": "2024-06-17T08:50:55.758Z",
      "commision": 245.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 122.88 and MARQUISE = 2% = 122.88"
    },
    {
      "_id": "666ff91971047f86749bd892",
      "userId": {
        "_id": "666523c24da6038aa2f6172b",
        "fullName": "Jwngsar basumatary",
        "referId": "RIL869921774"
      },
      "grand_total": 249,
      "createdAt": "2024-06-17T08:51:37.952Z",
      "commision": 3.735,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 3.735"
    },
    {
      "_id": "666ffb6271047f86749bef78",
      "userId": {
        "_id": "666ff2af71047f86749b9f8e",
        "fullName": "Rangame M Sangma",
        "referId": "RIL286245113"
      },
      "grand_total": 25935,
      "createdAt": "2024-06-17T09:01:22.474Z",
      "commision": 1037.4,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 518.7 and MARQUISE = 2% = 518.7"
    },
    {
      "_id": "6670011771047f86749c18f0",
      "userId": {
        "_id": "666bbdc933b5f38b97252656",
        "fullName": "Relisa sangma",
        "referId": "RIL588855304"
      },
      "grand_total": 8595,
      "createdAt": "2024-06-17T09:25:43.749Z",
      "commision": 343.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 171.9 and MARQUISE = 2% = 171.9"
    },
    {
      "_id": "6670059671047f86749c2bb1",
      "userId": {
        "_id": "666feab471047f86749b431e",
        "fullName": "Monitha M Marak",
        "referId": "RIL173809699"
      },
      "grand_total": 8494,
      "createdAt": "2024-06-17T09:44:54.741Z",
      "commision": 339.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 169.88 and MARQUISE = 2% = 169.88"
    },
    {
      "_id": "6670482571047f86749da9fd",
      "userId": {
        "_id": "66703f9571047f86749d7978",
        "fullName": "Bhargab gogoi",
        "referId": "RIL417636672"
      },
      "grand_total": 20390,
      "createdAt": "2024-06-17T14:28:53.586Z",
      "commision": 305.84999999999997,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 305.84999999999997"
    },
    {
      "_id": "66712af669bc461147db2f63",
      "userId": {
        "_id": "666c07a133b5f38b9728e42b",
        "fullName": "Shimran chetri",
        "referId": "RIL663497763"
      },
      "grand_total": 12693,
      "createdAt": "2024-06-18T06:36:38.609Z",
      "commision": 253.86,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 126.93 and MARQUISE = 1% = 126.93"
    },
    {
      "_id": "667147f101f4509ae041bfa6",
      "userId": {
        "_id": "6669860c33b5f38b971502c4",
        "fullName": "Bilifang Basumatary",
        "referId": "RIL806979295"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-18T08:40:17.220Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "6671543a64df008d4356dbe8",
      "userId": {
        "_id": "667148284aee26f19d90990e",
        "fullName": "Prechi K Sangma",
        "referId": "RIL455496831"
      },
      "grand_total": 12590,
      "createdAt": "2024-06-18T09:32:42.143Z",
      "commision": 503.6,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 251.8 and MARQUISE = 2% = 251.8"
    },
    {
      "_id": "66715c9f4aee26f19d90c04c",
      "userId": {
        "_id": "66572c3f965893d079571f3e",
        "fullName": "Chelsia Rikkimbe T sangma",
        "referId": "RIL366070759"
      },
      "grand_total": 8445,
      "createdAt": "2024-06-18T10:08:31.523Z",
      "commision": 337.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 168.9 and MARQUISE = 2% = 168.9"
    },
    {
      "_id": "66718bf801f4509ae042263d",
      "userId": {
        "_id": "66713abe01f4509ae041ac51",
        "fullName": "Bhoban boro",
        "referId": "RIL309392651"
      },
      "grand_total": 18991,
      "createdAt": "2024-06-18T13:30:32.257Z",
      "commision": 189.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 189.91"
    },
    {
      "_id": "6671a88164df008d4357586b",
      "userId": {
        "_id": "667190d964df008d4357377e",
        "fullName": "Sewali Borah",
        "referId": "RIL261773975"
      },
      "grand_total": 8294,
      "createdAt": "2024-06-18T15:32:17.711Z",
      "commision": 82.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.94"
    },
    {
      "_id": "6671d10e01f4509ae0426d02",
      "userId": {
        "_id": "6671b7d64aee26f19d9136e2",
        "fullName": "Niba Tirkey",
        "referId": "RIL486007560"
      },
      "grand_total": 28384,
      "createdAt": "2024-06-18T18:25:18.011Z",
      "commision": 283.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 283.84000000000003"
    },
    {
      "_id": "6671d3ec69bc461147dc167b",
      "userId": {
        "_id": "6671500d4aee26f19d90a3fa",
        "fullName": "Nabajyoti Sarkar",
        "referId": "RIL750882933"
      },
      "grand_total": 41229,
      "createdAt": "2024-06-18T18:37:32.932Z",
      "commision": 412.29,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 412.29"
    },
    {
      "_id": "6671d4b64aee26f19d914c45",
      "userId": {
        "_id": "666fb67771047f867499b703",
        "fullName": "Anari Boro",
        "referId": "RIL659190160"
      },
      "grand_total": 32682,
      "createdAt": "2024-06-18T18:40:54.774Z",
      "commision": 326.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 326.82"
    },
    {
      "_id": "6671d5d069bc461147dc18ea",
      "userId": {
        "_id": "6671b48501f4509ae0425960",
        "fullName": "Sujita Dhara",
        "referId": "RIL231644229"
      },
      "grand_total": 27336,
      "createdAt": "2024-06-18T18:45:36.537Z",
      "commision": 273.36,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 273.36"
    },
    {
      "_id": "6671d95064df008d435781e0",
      "userId": {
        "_id": "6671615b4aee26f19d90cd7c",
        "fullName": "Rahul basumatri",
        "referId": "RIL828172531"
      },
      "grand_total": 16641,
      "createdAt": "2024-06-18T19:00:32.248Z",
      "commision": 166.41,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 166.41"
    },
    {
      "_id": "66727f6401f4509ae042d9a4",
      "userId": {
        "_id": "6672674c01f4509ae042a43f",
        "fullName": "Parag sonowal",
        "referId": "RIL218803759"
      },
      "grand_total": 32683,
      "createdAt": "2024-06-19T06:49:08.099Z",
      "commision": 980.49,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 326.83 and MARQUISE = 2% = 653.66"
    },
    {
      "_id": "6672948701f4509ae04307bb",
      "userId": {
        "_id": "666ee8bb71047f8674982157",
        "fullName": "Prodipson marak",
        "referId": "RIL108598460"
      },
      "grand_total": 2946,
      "createdAt": "2024-06-19T08:19:19.015Z",
      "commision": 117.84,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 58.92 and MARQUISE = 2% = 58.92"
    },
    {
      "_id": "66729c2264df008d435826a3",
      "userId": {
        "_id": "6671583b4aee26f19d90b76e",
        "fullName": "lirbon tokbipi",
        "referId": "RIL823182225"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-19T08:51:46.499Z",
      "commision": 124.44,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 124.44"
    },
    {
      "_id": "66729c8301f4509ae04317db",
      "userId": {
        "_id": "6671583b4aee26f19d90b76e",
        "fullName": "lirbon tokbipi",
        "referId": "RIL823182225"
      },
      "grand_total": 249,
      "createdAt": "2024-06-19T08:53:23.368Z",
      "commision": 3.735,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 3.735"
    },
    {
      "_id": "6672a4ec64df008d43583f03",
      "userId": {
        "_id": "666fdd9d71047f86749aaddc",
        "fullName": "Mayashri Deori",
        "referId": "RIL918109453"
      },
      "grand_total": 5997,
      "createdAt": "2024-06-19T09:29:16.321Z",
      "commision": 89.955,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 89.955"
    },
    {
      "_id": "6672f48a01f4509ae0439ab3",
      "userId": {
        "_id": "66703f9571047f86749d7978",
        "fullName": "Bhargab gogoi",
        "referId": "RIL417636672"
      },
      "grand_total": 11993,
      "createdAt": "2024-06-19T15:08:58.414Z",
      "commision": 179.89499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 179.89499999999998"
    },
    {
      "_id": "6672f77a64df008d4358affd",
      "userId": {
        "_id": "6671547501f4509ae041d599",
        "fullName": "Krishna tayeng",
        "referId": "RIL321938546"
      },
      "grand_total": 7996,
      "createdAt": "2024-06-19T15:21:30.995Z",
      "commision": 119.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 119.94"
    },
    {
      "_id": "6673ca5157e911169ebac52a",
      "userId": {
        "_id": "666d36e871047f867490b44c",
        "fullName": "Tenang G Momin",
        "referId": "RIL789873781"
      },
      "grand_total": 12643,
      "createdAt": "2024-06-20T06:21:05.258Z",
      "commision": 505.72,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 252.86 and MARQUISE = 2% = 252.86"
    },
    {
      "_id": "6673d09557e911169ebacf81",
      "userId": {
        "_id": "666bbdc933b5f38b97252656",
        "fullName": "Relisa sangma",
        "referId": "RIL588855304"
      },
      "grand_total": 12741,
      "createdAt": "2024-06-20T06:47:49.041Z",
      "commision": 509.64,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 254.82 and MARQUISE = 2% = 254.82"
    },
    {
      "_id": "6673d7fe250bb69ac73861a3",
      "userId": {
        "_id": "6673d349de2a384d755cbc32",
        "fullName": "Lakhya Jyoti Saikia",
        "referId": "RIL619656483"
      },
      "grand_total": 16792,
      "createdAt": "2024-06-20T07:19:26.641Z",
      "commision": 251.88,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 251.88"
    },
    {
      "_id": "6673dc73fe33fc60ca5c5c83",
      "userId": {
        "_id": "6673d73b250bb69ac7385f3f",
        "fullName": "Rajiv singha",
        "referId": "RIL827734916"
      },
      "grand_total": 40880,
      "createdAt": "2024-06-20T07:38:27.557Z",
      "commision": 1022,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 613.1999999999999 and MARQUISE = 1% = 408.8"
    },
    {
      "_id": "6673e6de250bb69ac7388511",
      "userId": {
        "_id": "666ab9ae33b5f38b971e1969",
        "fullName": "Apinash Basumatary",
        "referId": "RIL131085567"
      },
      "grand_total": 4147,
      "createdAt": "2024-06-20T08:22:54.788Z",
      "commision": 41.47,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 41.47"
    },
    {
      "_id": "6673fa8457e911169ebb23e3",
      "userId": {
        "_id": "6673e8e657e911169ebb016f",
        "fullName": "Karam koch",
        "referId": "RIL993421169"
      },
      "grand_total": 8395,
      "createdAt": "2024-06-20T09:46:44.917Z",
      "commision": 125.925,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 125.925"
    },
    {
      "_id": "667408cf57e911169ebb3d4f",
      "userId": {
        "_id": "666d548371047f867491ca38",
        "fullName": "KRISHNA BORUAH",
        "referId": "RIL469669566"
      },
      "grand_total": 15393,
      "createdAt": "2024-06-20T10:47:43.428Z",
      "commision": 384.825,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 230.89499999999998 and MARQUISE = 1% = 153.93"
    },
    {
      "_id": "66743636250bb69ac738f58c",
      "userId": {
        "_id": "6671583b4aee26f19d90b76e",
        "fullName": "lirbon tokbipi",
        "referId": "RIL823182225"
      },
      "grand_total": 8698,
      "createdAt": "2024-06-20T14:01:26.189Z",
      "commision": 130.47,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 130.47"
    },
    {
      "_id": "66751620250bb69ac7395f36",
      "userId": {
        "_id": "6674400b57e911169ebb76a1",
        "fullName": "NOBAMME N SANGMA",
        "referId": "RIL312710812"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T05:56:48.377Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667518d0de2a384d755dc5be",
      "userId": {
        "_id": "6674f07b250bb69ac7393875",
        "fullName": "Bilgrak R Sangma",
        "referId": "RIL815200341"
      },
      "grand_total": 17041,
      "createdAt": "2024-06-21T06:08:16.906Z",
      "commision": 681.64,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 340.82 and MARQUISE = 2% = 340.82"
    },
    {
      "_id": "667521dd250bb69ac7397869",
      "userId": {
        "_id": "66751ab7250bb69ac7396a86",
        "fullName": "TENGKIMCHI SANGMA",
        "referId": "RIL454369689"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T06:46:53.652Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "6675252757e911169ebbf4d1",
      "userId": {
        "_id": "6673fe41de2a384d755d125c",
        "fullName": "KRISHNA RAHANG",
        "referId": "RIL179695190"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T07:00:55.090Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66752b0c57e911169ebc00b7",
      "userId": {
        "_id": "666c03ce33b5f38b9728b8df",
        "fullName": "Partha pratim nath",
        "referId": "RIL313026602"
      },
      "grand_total": 4096,
      "createdAt": "2024-06-21T07:26:04.573Z",
      "commision": 61.44,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 61.44"
    },
    {
      "_id": "66752ce2fe33fc60ca5d7b83",
      "userId": {
        "_id": "6671b7d64aee26f19d9136e2",
        "fullName": "Niba Tirkey",
        "referId": "RIL486007560"
      },
      "grand_total": 11743,
      "createdAt": "2024-06-21T07:33:54.766Z",
      "commision": 117.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 117.43"
    },
    {
      "_id": "66752e7f57e911169ebc08ad",
      "userId": {
        "_id": "6673fe41de2a384d755d125c",
        "fullName": "KRISHNA RAHANG",
        "referId": "RIL179695190"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T07:40:47.988Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66752f11de2a384d755df4a6",
      "userId": {
        "_id": "6675278157e911169ebbf8c6",
        "fullName": "Sansurang Daimary",
        "referId": "RIL923818832"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-21T07:43:13.216Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "66752fc8fe33fc60ca5d828c",
      "userId": {
        "_id": "6673fe41de2a384d755d125c",
        "fullName": "KRISHNA RAHANG",
        "referId": "RIL179695190"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T07:46:16.827Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "6675343157e911169ebc16a9",
      "userId": {
        "_id": "6671615b4aee26f19d90cd7c",
        "fullName": "Rahul basumatri",
        "referId": "RIL828172531"
      },
      "grand_total": 15393,
      "createdAt": "2024-06-21T08:05:05.741Z",
      "commision": 153.93,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 153.93"
    },
    {
      "_id": "6675420bfe33fc60ca5da79f",
      "userId": {
        "_id": "666fc38171047f86749a009d",
        "fullName": "Lhinghoilhai Kholhou",
        "referId": "RIL389944378"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-21T09:04:11.825Z",
      "commision": 124.44,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 124.44"
    },
    {
      "_id": "6675450b57e911169ebc38dd",
      "userId": {
        "_id": "6675376657e911169ebc1bb9",
        "fullName": "Sameli Mochahari",
        "referId": "RIL590335969"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-21T09:16:59.254Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "66754e5b57e911169ebc5324",
      "userId": {
        "_id": "66752584fe33fc60ca5d6cd0",
        "fullName": "Priyanka Soren",
        "referId": "RIL686473754"
      },
      "grand_total": 32882,
      "createdAt": "2024-06-21T09:56:43.555Z",
      "commision": 328.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.82"
    },
    {
      "_id": "6675acf4fe33fc60ca5e4a2a",
      "userId": {
        "_id": "66751867de2a384d755dc4cc",
        "fullName": "Damayanti Ramchiary",
        "referId": "RIL850937686"
      },
      "grand_total": 32831,
      "createdAt": "2024-06-21T16:40:20.282Z",
      "commision": 328.31,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.31"
    },
    {
      "_id": "6675b2f957e911169ebcdde3",
      "userId": {
        "_id": "6673fc9cde2a384d755d1065",
        "fullName": "RWMWI NARZARY",
        "referId": "RIL714800944"
      },
      "grand_total": 6845,
      "createdAt": "2024-06-21T17:06:01.302Z",
      "commision": 68.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 68.45"
    },
    {
      "_id": "6675b90ede2a384d755ec9ee",
      "userId": {
        "_id": "6671522264df008d4356d6f9",
        "fullName": "Barasha Daimari",
        "referId": "RIL251473998"
      },
      "grand_total": 12043,
      "createdAt": "2024-06-21T17:31:58.028Z",
      "commision": 120.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 120.43"
    },
    {
      "_id": "6675bf7b57e911169ebceabb",
      "userId": {
        "_id": "6650dbac832396d411513748",
        "referId": "RIL301367863",
        "fullName": "Jexson Basumatary"
      },
      "grand_total": 14593,
      "createdAt": "2024-06-21T17:59:23.828Z",
      "commision": 145.93,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 145.93"
    },
    {
      "_id": "667672fefe33fc60ca5ec8b9",
      "userId": {
        "_id": "667521eb250bb69ac739792b",
        "fullName": "Munindra boro",
        "referId": "RIL831168122"
      },
      "grand_total": 12643,
      "createdAt": "2024-06-22T06:45:18.231Z",
      "commision": 126.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 126.43"
    },
    {
      "_id": "667680d257e911169ebd740b",
      "userId": {
        "_id": "66764c0857e911169ebd10a5",
        "fullName": "Inamul hoque",
        "referId": "RIL484511127"
      },
      "grand_total": 8644,
      "createdAt": "2024-06-22T07:44:18.512Z",
      "commision": 86.44,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 86.44"
    },
    {
      "_id": "6676857b57e911169ebd83b6",
      "userId": {
        "_id": "667565af250bb69ac73a0ed9",
        "fullName": "Abhishek Bagchi",
        "referId": "RIL808419382"
      },
      "grand_total": 8244,
      "createdAt": "2024-06-22T08:04:11.259Z",
      "commision": 164.88,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.44 and MARQUISE = 1% = 82.44"
    },
    {
      "_id": "66768e65fe33fc60ca5f079d",
      "userId": {
        "_id": "6656c961965893d079529346",
        "fullName": "GEETA BRAHMA",
        "referId": "RIL356990450"
      },
      "grand_total": 14991,
      "createdAt": "2024-06-22T08:42:13.600Z",
      "commision": 149.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 149.91"
    },
    {
      "_id": "66768f2afe33fc60ca5f0a3b",
      "userId": {
        "_id": "6671583b4aee26f19d90b76e",
        "fullName": "lirbon tokbipi",
        "referId": "RIL823182225"
      },
      "grand_total": 8796,
      "createdAt": "2024-06-22T08:45:30.010Z",
      "commision": 131.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 131.94"
    },
    {
      "_id": "66769600fe33fc60ca5f18c6",
      "userId": {
        "_id": "667690e957e911169ebd99d9",
        "fullName": "Choideep tayang",
        "referId": "RIL608199647"
      },
      "grand_total": 21490,
      "createdAt": "2024-06-22T09:14:40.656Z",
      "commision": 537.25,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 322.34999999999997 and MARQUISE = 1% = 214.9"
    },
    {
      "_id": "66769698fe33fc60ca5f1ae9",
      "userId": {
        "_id": "665967a64a75d84c757624a1",
        "fullName": "EDRISH ALI",
        "referId": "RIL167706521"
      },
      "grand_total": 5996,
      "createdAt": "2024-06-22T09:17:12.332Z",
      "commision": 59.96,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 59.96"
    },
    {
      "_id": "6676a1d657e911169ebdb7a7",
      "userId": {
        "_id": "665967a64a75d84c757624a1",
        "fullName": "EDRISH ALI",
        "referId": "RIL167706521"
      },
      "grand_total": 1248,
      "createdAt": "2024-06-22T10:05:10.623Z",
      "commision": 12.48,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 12.48"
    },
    {
      "_id": "6676c71757e911169ebdf085",
      "userId": {
        "_id": "66718a3a01f4509ae04223c2",
        "fullName": "Jasper Thiaite",
        "referId": "RIL174263051"
      },
      "grand_total": 32734,
      "createdAt": "2024-06-22T12:44:07.514Z",
      "commision": 654.6800000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.34000000000003 and MARQUISE = 1% = 327.34000000000003"
    },
    {
      "_id": "6676d43557e911169ebe0616",
      "userId": {
        "_id": "666ff2af71047f86749b9f8e",
        "fullName": "Rangame M Sangma",
        "referId": "RIL286245113"
      },
      "grand_total": 6695,
      "createdAt": "2024-06-22T13:40:05.195Z",
      "commision": 267.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 133.9 and MARQUISE = 2% = 133.9"
    },
    {
      "_id": "6676e9ed250bb69ac73b97a9",
      "userId": {
        "_id": "6650dbac832396d411513770",
        "referId": "RIL301749522",
        "fullName": "Tenam  Sangma "
      },
      "grand_total": 7996,
      "createdAt": "2024-06-22T15:12:45.953Z",
      "commision": 799.6000000000001,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 159.92000000000002 and MARQUISE = 8% = 639.6800000000001"
    },
    {
      "_id": "6676f8b9de2a384d75600a79",
      "userId": {
        "_id": "667675f2de2a384d755f3e3e",
        "fullName": "Sumani Murah",
        "referId": "RIL654356483"
      },
      "grand_total": 17139,
      "createdAt": "2024-06-22T16:15:53.219Z",
      "commision": 171.39000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 171.39000000000001"
    },
    {
      "_id": "6677abd9250bb69ac73bed04",
      "userId": {
        "_id": "667195d164df008d43573f06",
        "fullName": "Pubali Borah",
        "referId": "RIL271040049"
      },
      "grand_total": 8294,
      "createdAt": "2024-06-23T05:00:09.734Z",
      "commision": 82.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.94"
    },
    {
      "_id": "6677b9df250bb69ac73c0e0c",
      "userId": {
        "_id": "66598a434a75d84c75794385",
        "fullName": "Divya Rajbhar",
        "referId": "RIL902866649"
      },
      "grand_total": 32584,
      "createdAt": "2024-06-23T05:59:59.305Z",
      "commision": 2280.88,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 651.6800000000001 and MARQUISE = 5% = 1629.2"
    },
    {
      "_id": "6677c29cfe33fc60ca60103b",
      "userId": {
        "_id": "6677b7f8de2a384d75606c54",
        "fullName": "Rinchin Tsering Thongkhar",
        "referId": "RIL858163799"
      },
      "grand_total": 32688,
      "createdAt": "2024-06-23T06:37:16.184Z",
      "commision": 2288.16,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 653.76 and MARQUISE = 5% = 1634.4"
    },
    {
      "_id": "6677ceae57e911169ebeb435",
      "userId": {
        "_id": "6677bda9de2a384d756079ec",
        "fullName": "Kajal Basumatary",
        "referId": "RIL620196670"
      },
      "grand_total": 32333,
      "createdAt": "2024-06-23T07:28:46.852Z",
      "commision": 323.33,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 323.33"
    },
    {
      "_id": "66790bfd57e911169ebf9bec",
      "userId": {
        "_id": "6650db9e832396d41150cc06",
        "referId": "RIL26297455",
        "fullName": "Emon jyoti Baruah"
      },
      "grand_total": 1050,
      "createdAt": "2024-06-24T06:02:37.585Z",
      "commision": 21,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 10.5 and MARQUISE = 1% = 10.5"
    },
    {
      "_id": "66790f6e250bb69ac73d28b7",
      "userId": {
        "_id": "665ebe93642ec7378986d5a8",
        "fullName": "Semba",
        "referId": "RIL826249039"
      },
      "grand_total": 8546,
      "createdAt": "2024-06-24T06:17:18.881Z",
      "commision": 341.84000000000003,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 170.92000000000002 and MARQUISE = 2% = 170.92000000000002"
    },
    {
      "_id": "667910cede2a384d756186b6",
      "userId": {
        "_id": "6677dc9b250bb69ac73c4e09",
        "fullName": "Kisan R Marak",
        "referId": "RIL647735226"
      },
      "grand_total": 32680,
      "createdAt": "2024-06-24T06:23:10.066Z",
      "commision": 1307.2,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 653.6 and MARQUISE = 2% = 653.6"
    },
    {
      "_id": "6679166efe33fc60ca612186",
      "userId": {
        "_id": "6674f07b250bb69ac7393875",
        "fullName": "Bilgrak R Sangma",
        "referId": "RIL815200341"
      },
      "grand_total": 15142,
      "createdAt": "2024-06-24T06:47:10.211Z",
      "commision": 605.6800000000001,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 302.84000000000003 and MARQUISE = 2% = 302.84000000000003"
    },
    {
      "_id": "66792e75fe33fc60ca614416",
      "userId": {
        "_id": "667690e957e911169ebd99d9",
        "fullName": "Choideep tayang",
        "referId": "RIL608199647"
      },
      "grand_total": 10995,
      "createdAt": "2024-06-24T08:29:41.137Z",
      "commision": 274.875,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 164.92499999999998 and MARQUISE = 1% = 109.95"
    },
    {
      "_id": "66793c6457e911169ebfeaa8",
      "userId": {
        "_id": "66791d1f57e911169ebfb9d0",
        "fullName": "Swarup daimari",
        "referId": "RIL420052742"
      },
      "grand_total": 7996,
      "createdAt": "2024-06-24T09:29:08.286Z",
      "commision": 119.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 119.94"
    },
    {
      "_id": "66795aea250bb69ac73da611",
      "userId": {
        "_id": "6678dd56de2a384d756144a3",
        "fullName": "Rahul Munda",
        "referId": "RIL853180374"
      },
      "grand_total": 8395,
      "createdAt": "2024-06-24T11:39:22.930Z",
      "commision": 587.65,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 167.9 and MARQUISE = 5% = 419.75"
    },
    {
      "_id": "66795bc757e911169ec02897",
      "userId": {
        "_id": "6674400b57e911169ebb76a1",
        "fullName": "NOBAMME N SANGMA",
        "referId": "RIL312710812"
      },
      "grand_total": 24438,
      "createdAt": "2024-06-24T11:43:03.612Z",
      "commision": 244.38,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 244.38"
    },
    {
      "_id": "6679724ede2a384d75621bbb",
      "userId": {
        "_id": "667561fb250bb69ac73a0709",
        "fullName": "Uttpal paik",
        "referId": "RIL289819592"
      },
      "grand_total": 8147,
      "createdAt": "2024-06-24T13:19:10.517Z",
      "commision": 162.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 81.47 and MARQUISE = 1% = 81.47"
    },
    {
      "_id": "66797f7c250bb69ac73dd3d7",
      "userId": {
        "_id": "6677c307250bb69ac73c20ef",
        "fullName": "Pema khandu Thongkhar",
        "referId": "RIL319464349"
      },
      "grand_total": 32587,
      "createdAt": "2024-06-24T14:15:24.620Z",
      "commision": 2281.09,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 651.74 and MARQUISE = 5% = 1629.3500000000001"
    },
    {
      "_id": "6679902a250bb69ac73de236",
      "userId": {
        "_id": "66756beffe33fc60ca5e033e",
        "fullName": "Biplob paik",
        "referId": "RIL183954123"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-24T15:26:34.735Z",
      "commision": 164.9,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45 and MARQUISE = 1% = 82.45"
    },
    {
      "_id": "667997ec250bb69ac73deb18",
      "userId": {
        "_id": "6677aec5de2a384d7560592a",
        "fullName": "NOKME BIDANCHI CH SANGMA",
        "referId": "RIL458174004"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-24T15:59:40.301Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66799ce3250bb69ac73df2f6",
      "userId": {
        "_id": "66756efcde2a384d755e7992",
        "fullName": "Ananta Biswas",
        "referId": "RIL811855991"
      },
      "grand_total": 21240,
      "createdAt": "2024-06-24T16:20:51.007Z",
      "commision": 424.8,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 212.4 and MARQUISE = 1% = 212.4"
    },
    {
      "_id": "66799dfc57e911169ec07660",
      "userId": {
        "_id": "665991ae4a75d84c7579ef4a",
        "fullName": "Rajib koch",
        "referId": "RIL651139496"
      },
      "grand_total": 10995,
      "createdAt": "2024-06-24T16:25:32.251Z",
      "commision": 274.875,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 164.92499999999998 and MARQUISE = 1% = 109.95"
    },
    {
      "_id": "66799e9657e911169ec07876",
      "userId": {
        "_id": "66797467fe33fc60ca61ae6f",
        "fullName": "Dawa Tsering Thongkar",
        "referId": "RIL833991466"
      },
      "grand_total": 32688,
      "createdAt": "2024-06-24T16:28:06.458Z",
      "commision": 2288.16,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 653.76 and MARQUISE = 5% = 1634.4"
    },
    {
      "_id": "6679a5eafe33fc60ca61e820",
      "userId": {
        "_id": "666d325d71047f8674909575",
        "fullName": "Nur Mahammad",
        "referId": "RIL479724611"
      },
      "grand_total": 26088,
      "createdAt": "2024-06-24T16:59:22.750Z",
      "commision": 260.88,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 260.88"
    },
    {
      "_id": "6679ac40250bb69ac73e06ce",
      "userId": {
        "_id": "66782b19de2a384d75610877",
        "fullName": "SANJITA BORO",
        "referId": "RIL885170895"
      },
      "grand_total": 7945,
      "createdAt": "2024-06-24T17:26:24.610Z",
      "commision": 79.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 79.45"
    },
    {
      "_id": "6679acaade2a384d75625fce",
      "userId": {
        "_id": "6671583b4aee26f19d90b76e",
        "fullName": "lirbon tokbipi",
        "referId": "RIL823182225"
      },
      "grand_total": 6944,
      "createdAt": "2024-06-24T17:28:10.249Z",
      "commision": 104.16,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 104.16"
    },
    {
      "_id": "6679ae4cde2a384d75626360",
      "userId": {
        "_id": "667573cede2a384d755e80ae",
        "fullName": "Minowara parbin",
        "referId": "RIL420371838"
      },
      "grand_total": 21587,
      "createdAt": "2024-06-24T17:35:08.987Z",
      "commision": 215.87,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 215.87"
    },
    {
      "_id": "6679b017de2a384d75626ac5",
      "userId": {
        "_id": "66794a7e250bb69ac73d8da3",
        "fullName": "Rebecca Durongpi",
        "referId": "RIL515391378"
      },
      "grand_total": 8493,
      "createdAt": "2024-06-24T17:42:47.639Z",
      "commision": 127.395,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 127.395"
    },
    {
      "_id": "6679b02457e911169ec08f4f",
      "userId": {
        "_id": "66782b19de2a384d75610877",
        "fullName": "SANJITA BORO",
        "referId": "RIL885170895"
      },
      "grand_total": 249,
      "createdAt": "2024-06-24T17:43:00.917Z",
      "commision": 2.49,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 2.49"
    },
    {
      "_id": "6679bf06fe33fc60ca6205d9",
      "userId": {
        "_id": "667549dffe33fc60ca5db9da",
        "fullName": "Rajib Boro",
        "referId": "RIL459618386"
      },
      "grand_total": 40880,
      "createdAt": "2024-06-24T18:46:30.184Z",
      "commision": 408.8,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 408.8"
    },
    {
      "_id": "667a436ffe33fc60ca622f85",
      "userId": {
        "_id": "666c07a133b5f38b9728e42b",
        "fullName": "Shimran chetri",
        "referId": "RIL663497763"
      },
      "grand_total": 19888,
      "createdAt": "2024-06-25T04:11:27.893Z",
      "commision": 397.76,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 198.88 and MARQUISE = 1% = 198.88"
    },
    {
      "_id": "667a5108fe33fc60ca6247ff",
      "userId": {
        "_id": "66793772fe33fc60ca6150cf",
        "fullName": "Mwnthai Gwra Baro",
        "referId": "RIL354921807"
      },
      "grand_total": 25739,
      "createdAt": "2024-06-25T05:09:28.723Z",
      "commision": 257.39,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 257.39"
    },
    {
      "_id": "667a566cfe33fc60ca6255e5",
      "userId": {
        "_id": "66794c8ede2a384d7561ed3f",
        "fullName": "Riya tirkey",
        "referId": "RIL957625919"
      },
      "grand_total": 16992,
      "createdAt": "2024-06-25T05:32:28.673Z",
      "commision": 169.92000000000002,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 169.92000000000002"
    },
    {
      "_id": "667a706157e911169ec11a90",
      "userId": {
        "_id": "667521eb250bb69ac739792b",
        "fullName": "Munindra boro",
        "referId": "RIL831168122"
      },
      "grand_total": 27587,
      "createdAt": "2024-06-25T07:23:13.846Z",
      "commision": 275.87,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 275.87"
    },
    {
      "_id": "667a76a7250bb69ac73ea926",
      "userId": {
        "_id": "6671615b4aee26f19d90cd7c",
        "fullName": "Rahul basumatri",
        "referId": "RIL828172531"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-25T07:49:59.650Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667a79a4250bb69ac73eb49c",
      "userId": {
        "_id": "667950fede2a384d7561f47f",
        "fullName": "Serlini beypi",
        "referId": "RIL580456731"
      },
      "grand_total": 33183,
      "createdAt": "2024-06-25T08:02:44.225Z",
      "commision": 497.745,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 497.745"
    },
    {
      "_id": "667a885757e911169ec16529",
      "userId": {
        "_id": "667a6f7f250bb69ac73e9361",
        "fullName": "Robiul Islam",
        "referId": "RIL180158903"
      },
      "grand_total": 33083,
      "createdAt": "2024-06-25T09:05:27.127Z",
      "commision": 330.83,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.83"
    },
    {
      "_id": "667a91dafe33fc60ca62e992",
      "userId": {
        "_id": "667a6a0a250bb69ac73e89f5",
        "fullName": "Shuraj Ali",
        "referId": "RIL863623048"
      },
      "grand_total": 41230,
      "createdAt": "2024-06-25T09:46:02.109Z",
      "commision": 412.3,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 412.3"
    },
    {
      "_id": "667a941f250bb69ac73efdfc",
      "userId": {
        "_id": "667a6284250bb69ac73e7d40",
        "fullName": "Abu bakkar siddique",
        "referId": "RIL804518755"
      },
      "grand_total": 23239,
      "createdAt": "2024-06-25T09:55:43.949Z",
      "commision": 232.39000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 232.39000000000001"
    },
    {
      "_id": "667ad0c2de2a384d7563a840",
      "userId": {
        "_id": "667a822e250bb69ac73ecd9d",
        "fullName": "Mominul Islam",
        "referId": "RIL934526584"
      },
      "grand_total": 33235,
      "createdAt": "2024-06-25T14:14:26.436Z",
      "commision": 332.35,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 332.35"
    },
    {
      "_id": "667adcc4250bb69ac73f653e",
      "userId": {
        "_id": "66758f5457e911169ebcba22",
        "fullName": "DHARITRI BHUMIJA",
        "referId": "RIL640213942"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-25T15:05:40.436Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667ae264fe33fc60ca635c01",
      "userId": {
        "_id": "666e5f6c71047f8674950873",
        "fullName": "REKHA SAIKIA",
        "referId": "RIL974682936"
      },
      "grand_total": 19737,
      "createdAt": "2024-06-25T15:29:40.773Z",
      "commision": 493.425,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 296.055 and MARQUISE = 1% = 197.37"
    },
    {
      "_id": "667ae99757e911169ec20339",
      "userId": {
        "_id": "665c28b44a75d84c758de41c",
        "fullName": "Khandakar Ejaj Islam",
        "referId": "RIL759247677"
      },
      "grand_total": 33179,
      "createdAt": "2024-06-25T16:00:23.171Z",
      "commision": 331.79,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.79"
    },
    {
      "_id": "667afa52fe33fc60ca638202",
      "userId": {
        "_id": "666d366871047f867490b14e",
        "fullName": "Rakibul Islam",
        "referId": "RIL348471227"
      },
      "grand_total": 8494,
      "createdAt": "2024-06-25T17:11:46.738Z",
      "commision": 84.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 84.94"
    },
    {
      "_id": "667b04c7fe33fc60ca638f1f",
      "userId": {
        "_id": "667a717a250bb69ac73e97cc",
        "fullName": "Mina Hamid",
        "referId": "RIL911105084"
      },
      "grand_total": 33284,
      "createdAt": "2024-06-25T17:56:23.203Z",
      "commision": 332.84000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 332.84000000000003"
    },
    {
      "_id": "667b8da6de2a384d75641739",
      "userId": {
        "_id": "667a6fd357e911169ec11946",
        "fullName": "Satyajit borchetia",
        "referId": "RIL384222482"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-26T03:40:22.750Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "667b9dc557e911169ec25889",
      "userId": {
        "_id": "6662da5d734b6c72d205ced9",
        "fullName": "Himashri Deka",
        "referId": "RIL765167493"
      },
      "grand_total": 17391,
      "createdAt": "2024-06-26T04:49:09.995Z",
      "commision": 434.775,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 260.865 and MARQUISE = 1% = 173.91"
    },
    {
      "_id": "667ba25d250bb69ac73fda70",
      "userId": {
        "_id": "66747427fe33fc60ca5d12d2",
        "fullName": "Pritam Nath",
        "referId": "RIL936191447"
      },
      "grand_total": 32883,
      "createdAt": "2024-06-26T05:08:45.610Z",
      "commision": 822.075,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 493.245 and MARQUISE = 1% = 328.83"
    },
    {
      "_id": "667bb288250bb69ac73ff32d",
      "userId": {
        "_id": "6669238733b5f38b97102730",
        "fullName": "Jalu Ali",
        "referId": "RIL600255825"
      },
      "grand_total": 32633,
      "createdAt": "2024-06-26T06:17:44.408Z",
      "commision": 326.33,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 326.33"
    },
    {
      "_id": "667bbcfd57e911169ec296f4",
      "userId": {
        "_id": "6650dbac832396d41151393d",
        "referId": "RIL306456414",
        "fullName": "Shoriful Islam"
      },
      "grand_total": 19890,
      "createdAt": "2024-06-26T07:02:21.567Z",
      "commision": 198.9,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 198.9"
    },
    {
      "_id": "667bcab9fe33fc60ca642652",
      "userId": {
        "_id": "666d95eb71047f867493415f",
        "fullName": "Bringwill Sangma",
        "referId": "RIL946983675"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-26T08:00:57.653Z",
      "commision": 824.5,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 164.9 and MARQUISE = 8% = 659.6"
    },
    {
      "_id": "667bd06c250bb69ac7403c4d",
      "userId": {
        "_id": "6671522264df008d4356d6f9",
        "fullName": "Barasha Daimari",
        "referId": "RIL251473998"
      },
      "grand_total": 28434,
      "createdAt": "2024-06-26T08:25:16.613Z",
      "commision": 284.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 284.34000000000003"
    },
    {
      "_id": "667bd656de2a384d7564ac27",
      "userId": {
        "_id": "667bca6ffe33fc60ca642525",
        "fullName": "Lucy Khelma",
        "referId": "RIL290902055"
      },
      "grand_total": 23788,
      "createdAt": "2024-06-26T08:50:30.221Z",
      "commision": 356.82,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 356.82"
    },
    {
      "_id": "667bd66a250bb69ac7404bd8",
      "userId": {
        "_id": "667bc594de2a384d75648028",
        "fullName": "Najma Akhtar",
        "referId": "RIL302317295"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-26T08:50:50.826Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667bf9b3de2a384d7564ec2c",
      "userId": {
        "_id": "667bacf1fe33fc60ca63e446",
        "fullName": "JAMAL ALI",
        "referId": "RIL853448049"
      },
      "grand_total": 33034,
      "createdAt": "2024-06-26T11:21:23.871Z",
      "commision": 330.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.34000000000003"
    },
    {
      "_id": "667c297f57e911169ec34660",
      "userId": {
        "_id": "6650dbac832396d411513503",
        "referId": "RIL295488176",
        "fullName": "Sakuntala Basumatary"
      },
      "grand_total": 8545,
      "createdAt": "2024-06-26T14:45:19.684Z",
      "commision": 85.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 85.45"
    },
    {
      "_id": "667c4184250bb69ac740db87",
      "userId": {
        "_id": "666fd3b771047f86749a67ab",
        "fullName": "Badal nunia",
        "referId": "RIL851355161"
      },
      "grand_total": 10995,
      "createdAt": "2024-06-26T16:27:48.552Z",
      "commision": 274.875,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 164.92499999999998 and MARQUISE = 1% = 109.95"
    },
    {
      "_id": "667c437cfe33fc60ca64d15d",
      "userId": {
        "_id": "667b0b85250bb69ac73f9787",
        "fullName": "Manik Uddin Ahmed",
        "referId": "RIL391788187"
      },
      "grand_total": 32836,
      "createdAt": "2024-06-26T16:36:12.711Z",
      "commision": 328.36,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.36"
    },
    {
      "_id": "667c476f57e911169ec3678a",
      "userId": {
        "_id": "667c425d57e911169ec36315",
        "fullName": "Pappu nunia",
        "referId": "RIL583434355"
      },
      "grand_total": 14593,
      "createdAt": "2024-06-26T16:53:03.815Z",
      "commision": 364.825,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 218.89499999999998 and MARQUISE = 1% = 145.93"
    },
    {
      "_id": "667c6abcfe33fc60ca64f0cd",
      "userId": {
        "_id": "667a6b2c250bb69ac73e8c27",
        "fullName": "Ibrahim Ali",
        "referId": "RIL956074522"
      },
      "grand_total": 33183,
      "createdAt": "2024-06-26T19:23:40.784Z",
      "commision": 331.83,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.83"
    },
    {
      "_id": "667cd6defe33fc60ca650651",
      "userId": {
        "_id": "66790e91250bb69ac73d25cd",
        "fullName": "Babina karki",
        "referId": "RIL324292229"
      },
      "grand_total": 12594,
      "createdAt": "2024-06-27T03:05:02.465Z",
      "commision": 503.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 251.88 and MARQUISE = 2% = 251.88"
    },
    {
      "_id": "667d0bdd250bb69ac74163e4",
      "userId": {
        "_id": "666d071571047f86748f7e23",
        "fullName": "Tangmera",
        "referId": "RIL402600358"
      },
      "grand_total": 15192,
      "createdAt": "2024-06-27T06:51:09.165Z",
      "commision": 607.6800000000001,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 303.84000000000003 and MARQUISE = 2% = 303.84000000000003"
    },
    {
      "_id": "667d1043de2a384d7565cdfe",
      "userId": {
        "_id": "6677ac2a250bb69ac73bf206",
        "fullName": "Sengkam R Marak",
        "referId": "RIL636954664"
      },
      "grand_total": 8545,
      "createdAt": "2024-06-27T07:09:55.374Z",
      "commision": 341.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 170.9 and MARQUISE = 2% = 170.9"
    },
    {
      "_id": "667d12fcfe33fc60ca656f0e",
      "userId": {
        "_id": "667bb575de2a384d756458eb",
        "fullName": "Rikrik A sangma",
        "referId": "RIL619928915"
      },
      "grand_total": 8545,
      "createdAt": "2024-06-27T07:21:32.143Z",
      "commision": 341.8,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 170.9 and MARQUISE = 2% = 170.9"
    },
    {
      "_id": "667d22f7de2a384d7565fb36",
      "userId": {
        "_id": "667d12e6250bb69ac74177c4",
        "fullName": "Kansai brahma",
        "referId": "RIL579922639"
      },
      "grand_total": 17041,
      "createdAt": "2024-06-27T08:29:43.167Z",
      "commision": 170.41,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 170.41"
    },
    {
      "_id": "667d281afe33fc60ca659ee5",
      "userId": {
        "_id": "666cfec733b5f38b972cb9ac",
        "fullName": "Nevil J Sangma",
        "referId": "RIL747216305"
      },
      "grand_total": 8494,
      "createdAt": "2024-06-27T08:51:38.427Z",
      "commision": 339.76,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 169.88 and MARQUISE = 2% = 169.88"
    },
    {
      "_id": "667d3727de2a384d756629c3",
      "userId": {
        "_id": "6673fe41de2a384d755d125c",
        "fullName": "KRISHNA RAHANG",
        "referId": "RIL179695190"
      },
      "grand_total": 15443,
      "createdAt": "2024-06-27T09:55:51.592Z",
      "commision": 154.43,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 154.43"
    },
    {
      "_id": "667d464ade2a384d756647b5",
      "userId": {
        "_id": "667596bdde2a384d755ea8e0",
        "fullName": "Ritunjoy Daimaray",
        "referId": "RIL159447815"
      },
      "grand_total": 32534,
      "createdAt": "2024-06-27T11:00:26.573Z",
      "commision": 325.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 325.34000000000003"
    },
    {
      "_id": "667d56e6a8cc5ccef07b931d",
      "userId": {
        "_id": "667add71fe33fc60ca635393",
        "fullName": "Esaiah",
        "referId": "RIL771989117"
      },
      "grand_total": 32987,
      "createdAt": "2024-06-27T12:11:18.380Z",
      "commision": 494.805,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 494.805"
    },
    {
      "_id": "667d8582a8cc5ccef07bcc92",
      "userId": {
        "_id": "667bbd82fe33fc60ca6409c7",
        "fullName": "Azizul Hoque",
        "referId": "RIL512234028"
      },
      "grand_total": 33034,
      "createdAt": "2024-06-27T15:30:10.893Z",
      "commision": 330.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 330.34000000000003"
    },
    {
      "_id": "667d8722a8cc5ccef07bd3c3",
      "userId": {
        "_id": "6665578e49cb8bbfbcbdeb90",
        "fullName": "Jilul Hoque",
        "referId": "RIL878830864"
      },
      "grand_total": 32834,
      "createdAt": "2024-06-27T15:37:06.313Z",
      "commision": 328.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.34000000000003"
    },
    {
      "_id": "667d9cfb4c7f29c6a7de0868",
      "userId": {
        "_id": "667d1a63250bb69ac741838c",
        "fullName": "Kuinmoni bora",
        "referId": "RIL882282489"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-27T17:10:19.032Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667d9d3a4c7f29c6a7de0997",
      "userId": {
        "_id": "667bc117fe33fc60ca641048",
        "fullName": "Saddam Hussain",
        "referId": "RIL625745734"
      },
      "grand_total": 26088,
      "createdAt": "2024-06-27T17:11:22.089Z",
      "commision": 260.88,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 260.88"
    },
    {
      "_id": "667e3048659db7d090d7ee6a",
      "userId": {
        "_id": "66794a7e250bb69ac73d8da3",
        "fullName": "Rebecca Durongpi",
        "referId": "RIL515391378"
      },
      "grand_total": 8845,
      "createdAt": "2024-06-28T03:38:48.155Z",
      "commision": 132.67499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 132.67499999999998"
    },
    {
      "_id": "667e3e57659db7d090d80c9a",
      "userId": {
        "_id": "666dc1e571047f8674942cc2",
        "fullName": "Deepanjali Bhumij",
        "referId": "RIL769774541"
      },
      "grand_total": 7894,
      "createdAt": "2024-06-28T04:38:47.326Z",
      "commision": 157.88,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 78.94 and MARQUISE = 1% = 78.94"
    },
    {
      "_id": "667e4b6aa8cc5ccef07c4fa4",
      "userId": {
        "_id": "667e45b74c7f29c6a7de5cc3",
        "fullName": "Vikash Ramchiary",
        "referId": "RIL556957750"
      },
      "grand_total": 41281,
      "createdAt": "2024-06-28T05:34:34.773Z",
      "commision": 412.81,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 412.81"
    },
    {
      "_id": "667e4fde324a393dac363ab6",
      "userId": {
        "_id": "66572529965893d07956eb1c",
        "fullName": "Prokash Jyoti Khaklari",
        "referId": "RIL175790324"
      },
      "grand_total": 24338,
      "createdAt": "2024-06-28T05:53:34.739Z",
      "commision": 365.07,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 365.07"
    },
    {
      "_id": "667e6a834c7f29c6a7deb9b3",
      "userId": {
        "_id": "667e589b659db7d090d84835",
        "fullName": "Abdul azed",
        "referId": "RIL209865912"
      },
      "grand_total": 17190,
      "createdAt": "2024-06-28T07:47:15.573Z",
      "commision": 171.9,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 171.9"
    },
    {
      "_id": "667e6d024c7f29c6a7dec95a",
      "userId": {
        "_id": "6673e463250bb69ac7387eb8",
        "fullName": "Bhagawat pran khanikar",
        "referId": "RIL804932913"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-28T07:57:54.437Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "667e819e4c7f29c6a7defe98",
      "userId": {
        "_id": "667e79f4324a393dac36b189",
        "fullName": "Amit Baishnab",
        "referId": "RIL534225622"
      },
      "grand_total": 41182,
      "createdAt": "2024-06-28T09:25:50.321Z",
      "commision": 1029.55,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 617.73 and MARQUISE = 1% = 411.82"
    },
    {
      "_id": "667e84a7659db7d090d8cdeb",
      "userId": {
        "_id": "667e7742659db7d090d8a451",
        "fullName": "Jonybirth sangma",
        "referId": "RIL611735828"
      },
      "grand_total": 33384,
      "createdAt": "2024-06-28T09:38:47.506Z",
      "commision": 500.76,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 500.76"
    },
    {
      "_id": "667e8eefa8cc5ccef07d03cb",
      "userId": {
        "_id": "667e81e3a8cc5ccef07cdfc2",
        "fullName": "Sudip debnath",
        "referId": "RIL440712942"
      },
      "grand_total": 41130,
      "createdAt": "2024-06-28T10:22:39.153Z",
      "commision": 1028.25,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 616.9499999999999 and MARQUISE = 1% = 411.3"
    },
    {
      "_id": "667ee4c9a8cc5ccef07d90c0",
      "userId": {
        "_id": "667c3d3efe33fc60ca64ca7b",
        "fullName": "Maina pegu",
        "referId": "RIL229457496"
      },
      "grand_total": 25987,
      "createdAt": "2024-06-28T16:28:57.554Z",
      "commision": 649.675,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 389.805 and MARQUISE = 1% = 259.87"
    },
    {
      "_id": "667ef299a8cc5ccef07da70c",
      "userId": {
        "_id": "667ce558250bb69ac74120e3",
        "fullName": "Barasha Changmai",
        "referId": "RIL886063223"
      },
      "grand_total": 40327,
      "createdAt": "2024-06-28T17:27:53.198Z",
      "commision": 1008.175,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 604.905 and MARQUISE = 1% = 403.27"
    },
    {
      "_id": "667ef2b9324a393dac37873e",
      "userId": {
        "_id": "667ec11d324a393dac372f67",
        "fullName": "Rashna goyary",
        "referId": "RIL737265569"
      },
      "grand_total": 32834,
      "createdAt": "2024-06-28T17:28:25.121Z",
      "commision": 328.34000000000003,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 328.34000000000003"
    },
    {
      "_id": "667fb12c33855655ec6799f8",
      "userId": {
        "_id": "66751ab7250bb69ac7396a86",
        "fullName": "TENGKIMCHI SANGMA",
        "referId": "RIL454369689"
      },
      "grand_total": 4198,
      "createdAt": "2024-06-29T07:01:00.828Z",
      "commision": 41.980000000000004,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 41.980000000000004"
    },
    {
      "_id": "667fbc9933855655ec67b88a",
      "userId": {
        "_id": "66794c8ede2a384d7561ed3f",
        "fullName": "Riya tirkey",
        "referId": "RIL957625919"
      },
      "grand_total": 15642,
      "createdAt": "2024-06-29T07:49:45.759Z",
      "commision": 156.42000000000002,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 156.42000000000002"
    },
    {
      "_id": "667fc3a9dd8b5fa5c6c57671",
      "userId": {
        "_id": "667fb861dd8b5fa5c6c552f2",
        "fullName": "Debesh Hajong",
        "referId": "RIL775432178"
      },
      "grand_total": 8545,
      "createdAt": "2024-06-29T08:19:53.126Z",
      "commision": 85.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 85.45"
    },
    {
      "_id": "667fcd06dd8b5fa5c6c5919e",
      "userId": {
        "_id": "667fb861dd8b5fa5c6c552f2",
        "fullName": "Debesh Hajong",
        "referId": "RIL775432178"
      },
      "grand_total": 8796,
      "createdAt": "2024-06-29T08:59:50.132Z",
      "commision": 87.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 87.96000000000001"
    },
    {
      "_id": "667fd215dd8b5fa5c6c59d34",
      "userId": {
        "_id": "667e76eb4c7f29c6a7dedd64",
        "fullName": "Shyamolima",
        "referId": "RIL905543636"
      },
      "grand_total": 8196,
      "createdAt": "2024-06-29T09:21:25.038Z",
      "commision": 81.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 81.96000000000001"
    },
    {
      "_id": "667fe1a9dd8b5fa5c6c5bc59",
      "userId": {
        "_id": "666d071571047f86748f7e23",
        "fullName": "Tangmera",
        "referId": "RIL402600358"
      },
      "grand_total": 7996,
      "createdAt": "2024-06-29T10:27:53.873Z",
      "commision": 319.84000000000003,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 159.92000000000002 and MARQUISE = 2% = 159.92000000000002"
    },
    {
      "_id": "66800c3333855655ec686443",
      "userId": {
        "_id": "667e697ca8cc5ccef07c9c53",
        "fullName": "Imdadul hoque",
        "referId": "RIL943800569"
      },
      "grand_total": 33135,
      "createdAt": "2024-06-29T13:29:23.075Z",
      "commision": 331.35,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 331.35"
    },
    {
      "_id": "66802481dd8b5fa5c6c63f04",
      "userId": {
        "_id": "667eb03e324a393dac371c10",
        "fullName": "Rafiqul Haque",
        "referId": "RIL364514023"
      },
      "grand_total": 8296,
      "createdAt": "2024-06-29T15:13:05.960Z",
      "commision": 82.96000000000001,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.96000000000001"
    },
    {
      "_id": "66802df033855655ec68a399",
      "userId": {
        "_id": "667e82754c7f29c6a7df01e8",
        "fullName": "Jakirul islam",
        "referId": "RIL110613170"
      },
      "grand_total": 41182,
      "createdAt": "2024-06-29T15:53:20.751Z",
      "commision": 411.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 411.82"
    },
    {
      "_id": "66804a739f73b698fd424074",
      "userId": {
        "_id": "667a81a5250bb69ac73ecc13",
        "fullName": "Pranita sarkar",
        "referId": "RIL984522899"
      },
      "grand_total": 26089,
      "createdAt": "2024-06-29T17:54:59.250Z",
      "commision": 260.89,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 260.89"
    },
    {
      "_id": "6680e7789f73b698fd428f61",
      "userId": {
        "_id": "66617493cc2b9b2859684f5e",
        "fullName": "Bishal Orang",
        "referId": "RIL190211045"
      },
      "grand_total": 8447,
      "createdAt": "2024-06-30T05:04:56.438Z",
      "commision": 168.94,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 84.47 and MARQUISE = 1% = 84.47"
    },
    {
      "_id": "6680eac333855655ec6922c5",
      "userId": {
        "_id": "667fe2b29f73b698fd418c6a",
        "fullName": "Teseng G Momin",
        "referId": "RIL612753453"
      },
      "grand_total": 8147,
      "createdAt": "2024-06-30T05:18:59.941Z",
      "commision": 325.88,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 162.94 and MARQUISE = 2% = 162.94"
    },
    {
      "_id": "6681075033855655ec696767",
      "userId": {
        "_id": "6679221bde2a384d7561a3fb",
        "fullName": "Bianchi Dipti K Sangma",
        "referId": "RIL818764103"
      },
      "grand_total": 8297,
      "createdAt": "2024-06-30T07:20:48.223Z",
      "commision": 331.88,
      "per_commision": "2",
      "per_commision_dis": "DUKE = 2% = 165.94 and MARQUISE = 2% = 165.94"
    },
    {
      "_id": "668108273dd33a1881adfd33",
      "userId": {
        "_id": "667f9c7c33855655ec67630a",
        "fullName": "Shamim imdadul hassan",
        "referId": "RIL798226491"
      },
      "grand_total": 32682,
      "createdAt": "2024-06-30T07:24:23.667Z",
      "commision": 326.82,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 326.82"
    },
    {
      "_id": "66810d44dd8b5fa5c6c725cd",
      "userId": {
        "_id": "66794a7e250bb69ac73d8da3",
        "fullName": "Rebecca Durongpi",
        "referId": "RIL515391378"
      },
      "grand_total": 15545,
      "createdAt": "2024-06-30T07:46:12.674Z",
      "commision": 233.17499999999998,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 233.17499999999998"
    },
    {
      "_id": "66810d73dd8b5fa5c6c727c1",
      "userId": {
        "_id": "666e8d9871047f867496388d",
        "fullName": "Samarjit das",
        "referId": "RIL426124688"
      },
      "grand_total": 8447,
      "createdAt": "2024-06-30T07:46:59.030Z",
      "commision": 126.705,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 126.705"
    },
    {
      "_id": "6681670233855655ec6a7f47",
      "userId": {
        "_id": "66810043dd8b5fa5c6c6fa3a",
        "fullName": "Kaushik Das",
        "referId": "RIL852002287"
      },
      "grand_total": 17191,
      "createdAt": "2024-06-30T14:09:06.129Z",
      "commision": 429.775,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 257.865 and MARQUISE = 1% = 171.91"
    },
    {
      "_id": "66816b279f73b698fd440478",
      "userId": {
        "_id": "667e860c4c7f29c6a7df0b98",
        "fullName": "Khairul Islam",
        "referId": "RIL235656485"
      },
      "grand_total": 17091,
      "createdAt": "2024-06-30T14:26:47.924Z",
      "commision": 170.91,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 170.91"
    },
    {
      "_id": "668172379f73b698fd442953",
      "userId": {
        "_id": "666c063f33b5f38b9728d6d1",
        "fullName": "Akkas Ali",
        "referId": "RIL881337721"
      },
      "grand_total": 32731,
      "createdAt": "2024-06-30T14:56:55.375Z",
      "commision": 654.62,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 327.31 and MARQUISE = 1% = 327.31"
    },
    {
      "_id": "66817d2f9f73b698fd445c47",
      "userId": {
        "_id": "666eee7b71047f8674982db9",
        "fullName": "Chiranjit Patir",
        "referId": "RIL779918500"
      },
      "grand_total": 8796,
      "createdAt": "2024-06-30T15:43:43.062Z",
      "commision": 131.94,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 131.94"
    },
    {
      "_id": "66817fc833855655ec6aec3d",
      "userId": {
        "_id": "667d89d4659db7d090d7b7a1",
        "fullName": "Didwm Baro",
        "referId": "RIL367577078"
      },
      "grand_total": 8147,
      "createdAt": "2024-06-30T15:54:48.485Z",
      "commision": 81.47,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 81.47"
    },
    {
      "_id": "668186663dd33a1881afaa00",
      "userId": {
        "_id": "66817d073dd33a1881af774c",
        "fullName": "Jerbom Taid",
        "referId": "RIL739815693"
      },
      "grand_total": 7896,
      "createdAt": "2024-06-30T16:23:02.222Z",
      "commision": 118.44,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 118.44"
    },
    {
      "_id": "66818d85dd8b5fa5c6c8ec77",
      "userId": {
        "_id": "667fa9ec33855655ec67837f",
        "fullName": "Hafizur Rahman",
        "referId": "RIL823445916"
      },
      "grand_total": 41126,
      "createdAt": "2024-06-30T16:53:25.931Z",
      "commision": 411.26,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 411.26"
    },
    {
      "_id": "668190919f73b698fd44b815",
      "userId": {
        "_id": "667e8978659db7d090d8db7b",
        "fullName": "Akibar Ali",
        "referId": "RIL979868897"
      },
      "grand_total": 8245,
      "createdAt": "2024-06-30T17:06:25.154Z",
      "commision": 82.45,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 82.45"
    },
    {
      "_id": "668192a39f73b698fd44c135",
      "userId": {
        "_id": "66800d8333855655ec68676a",
        "fullName": "Bibek phukan",
        "referId": "RIL486494147"
      },
      "grand_total": 17042,
      "createdAt": "2024-06-30T17:15:15.589Z",
      "commision": 511.26000000000005,
      "per_commision": "1",
      "per_commision_dis": "DUKE = 1% = 170.42000000000002 and MARQUISE = 2% = 340.84000000000003"
    },
    {
      "_id": "66819899dd8b5fa5c6c914f0",
      "userId": {
        "_id": "666e5f6c71047f8674950873",
        "fullName": "REKHA SAIKIA",
        "referId": "RIL974682936"
      },
      "grand_total": 4398,
      "createdAt": "2024-06-30T17:40:41.951Z",
      "commision": 109.95,
      "per_commision": "1.5",
      "per_commision_dis": "DUKE = 1.5% = 65.97 and MARQUISE = 1% = 43.980000000000004"
    }]
var ttt = 0
    ary?.map((data)=>{

      ttt = ttt + data?.grand_total
      return data
    })
// console.log(ttt);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [userData, setuserData] = useState({ phone: "", password: "" });
  const [type, settype] = useState("User");
  const dispatch = useDispatch();
  const router = useNavigate();

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };

  const hendletoSubmit = (e) => {
    e.preventDefault();

    if (!userData?.phone) {
      toast.error("please enter phone number");
    } else if (!userData?.password) {
      toast.error("please enter password");
    } else {
      userData.role_name = type;
      userData.phone = userData?.phone.toUpperCase();

        //  let uppercaseStr = loginData?.phone.toUpperCase();
      dispatch(allapiAction.signinSeller(userData, router));
      return () => {};
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "loginpage" }));

    return () => {};
  }, []);

  return (
    <div>
      {" "}
      <div className="hometest">
        <HomeSlider />
      </div>
      <div className="ps-page--my-account">
        {/* <div className="ps-breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>My account</li>
            </ul>
          </div>
        </div> */}
        <div className="ps-my-account">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <form className="ps-form--account ps-tab-root">
                  <div className="ps-tabs">
                    <div className="ps-tab active" id="sign-in">
                      <ul className="ps-tab-list">
                        <li
                          onClick={() => {
                            settype("User");
                          }}
                          className={
                            type == "User"
                              ? "active tbbtn  gradient-bg"
                              : "tbbtn"
                          }
                        >
                          <a href="#">Customer</a>
                        </li>
                        {/* <li
                          className={
                            type == "Vendor"
                              ? "active tbbtn gradient-bg"
                              : " tbbtn"
                          }
                          onClick={() => {
                            settype("Vendor");
                          }}
                        >
                          <a href="#">Vendor</a>
                        </li> */}
                      </ul>
                      <div className="ps-form__content">
                        <h5>Log In Your Account</h5>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter id"
                            name="phone"
                            onChange={(e) => {
                              hendletochange(e);
                            }}
                          />
                        </div>
                        <div className="form-group form-forgot">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter password"
                            name="password"
                            onChange={(e) => {
                              hendletochange(e);
                            }}
                          />
                          {/* <a href="#">Forgot?</a> */}
                        </div>
                        {/* <div className="form-group">
                      <div className="ps-checkbox">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="remember-me"
                          name="remember-me"
                        />
                        <label for="remember-me">Rememeber me</label>
                      </div>
                    </div> */}
                        <div className="form-group submtit">
                          <button
                            onClick={(e) => {
                              hendletoSubmit(e);
                            }}
                            className="ps-btn ps-btn--fullwidth"
                          >
                            Login
                          </button>
                        </div>
                        <div className="register-link">
                          <p>
                            Don't have an account?{" "}
                            <Link to="/register">Register</Link>
                          </p>
                        </div>
                      </div>
                      <div className="ps-form__footer">
                        {/* <p>Connect with:</p>
                    <ul className="ps-list--social">
                      <li>
                        <a className="facebook" href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="google" href="#">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="#">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    </div>
                    <div className="ps-tab" id="register">
                      <div className="ps-form__content">
                        <h5>Register An Account</h5>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Username or email address"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Password"
                          />
                        </div>
                        <div className="form-group submtit">
                          <button className="ps-btn ps-btn--fullwidth">
                            Login
                          </button>
                        </div>

                        <div className="register-link">
                          <p>
                            Don't have an account?{" "}
                            <Link to="/register">Register</Link>
                          </p>
                        </div>
                      </div>
                      <div className="ps-form__footer">
                        {/* <p>Connect with:</p>
                    <ul className="ps-list--social">
                      <li>
                        <a className="facebook" href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="google" href="#">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="#">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <div className="ps-section__right row">
                  <div className="col-sm-12 col-12 adsboxcus1">
                    {ad && (
                      <Slider {...settings}>
                        {ad &&
                          ad?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                  <div className="col-sm-12 col-12 adsboxcus1">
                    {ads2 && (
                      <Slider {...settings}>
                        {ads2 &&
                          ads2?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hometest">
        <HomeSlider />
      </div>
    </div>
  );
}

export default LonginComponent;
