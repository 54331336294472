import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
// import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Boxes3 = () => {
  const navigate = useNavigate();
  // const deshboard = useSelector((state) =>
  //   state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  // );
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );

  console.log(deshboard);
  

  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd3  m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              {" "}
              {deshboard?.DirectTotalf ? deshboard?.DirectTotalf?.toFixed(2) : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Direct Income</div>
            {/* <UsergroupDeleteOutlined /> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd4  m-5 cursor-pointer"
          // onClick={() => navigate("/add-lost-found")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {/* Add Lost And Found */}
              {deshboard?.levelTotalf ? deshboard?.levelTotalf?.toFixed(2) : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {/* {deshboard?.completeOrder} */}
            Level Income
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd1  m-5 cursor-pointer"
          // onClick={() => navigate("/generate-certificate")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              {/* Certificates */}
              {deshboard?.levelAchiveTotalf ? deshboard?.levelAchiveTotalf?.toFixed(2) : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Level Achive Income</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd2 m-5 cursor-pointer"
          onClick={() => navigate("/student")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">  {deshboard?.reDirectTotalf ? deshboard?.reDirectTotalf?.toFixed(2) : 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Re Direct Income</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd5  m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=2")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600  text-white"> {deshboard?.DirectTeam ? deshboard?.DirectTeam : 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Direct Team</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd6 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=3")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600  text-white"> {deshboard?.allTeam ? deshboard?.allTeam : 0} </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Team</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd7 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=4")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600"> {deshboard?.selfDonation ? deshboard?.selfDonation : 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Self Donation </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd8 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=5")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600 text-white" >{deshboard?.TeamDonation ? deshboard?.TeamDonation : 0}</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Team Donation</div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd3 m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=5")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600"></div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Competition</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd4   m-5 cursor-pointer"
          // onClick={() => navigate("/users?page=1&status=5")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600"></div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Friends</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd1  m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.companyBusinessWithGst}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-ct")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgct}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-mq")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Gpg mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgmq}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate("/gpg-duqe")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgduqe}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
        //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total admin commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.adminWinCommission}</div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox dashboardBoxShad2 cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes3;
