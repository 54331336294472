import { Col, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { URL } from "@/Redux/common/url";
import { Button, Modal } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
// import { allapiAction } from "@/Redux/common/action";
function BankBox() {
  const get_vendor_profiles = useSelector((state) =>
    state.allapi.get_vendor_profiles ? state.allapi.get_vendor_profiles : {}
  );
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [userBuss, setuserBuss] = useState({});
  const [userBuss1, setuserBuss1] = useState({});
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();

  console.log(get_vendor_profiles);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  const hendleChangerole1 = (e) => {
    const { name, value } = e.target;
    setuserBuss1({ ...userBuss1, [name]: value });
  };
  const maxNumber = 1;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  useEffect(() => {
    if (get_vendor_profiles) {
      // URL.API_BASE_URL + get_vendor_profiles?.sellerProfile

      setuserBuss(get_vendor_profiles?.kyc_id);
      // setImages([
      //   { data_url: URL.API_BASE_URL + get_vendor_profiles?.sellerProfile },
      // ]);
    }
  }, [get_vendor_profiles]);

  console.log(get_vendor_profiles);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };
  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );

  const submitProduct = () => {
    const data = new FormData();

    dispatch(
      allapiAction.kycUpdate({
        acc_holder: userBuss?.acc_holder,
        ifsc: userBuss?.ifsc,
        acc_no: userBuss?.acc_no,
        bank_name: userBuss?.bank_name,
        branch_name: userBuss?.branch_name,
        pan_card: userBuss?.pan_card,
      })
    );

    setShow(false);
  };
  const submitProduct1 = () => {
    const data = new FormData();

    if (!userBuss1?.password) {
     return toast?.error("Please Enter Old Password");
    }
    if (!userBuss1?.newPassword) {
     return toast?.error("Please Enter New Password");
    }
    if (!userBuss1?.confirmPassword) {
     return toast?.error("Please Enter Confirm Password");
    }
    if (userBuss1?.newPassword !==userBuss1?.confirmPassword) {
     return toast?.error("Please Enter Confirm Password");
    }

    dispatch(
      allapiAction.passwordChange({
        password: userBuss1?.password,
        newPassword: userBuss1?.newPassword,
      
       
      })
    );

    setShow1(false);
  };
  return (
    <Row gutter={[0, 20]}>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Old Password</label>
                <input
                  name="password"
                  className="form-control"
                  placeholder="Enter Old Password"
                  type="text"
                  value={userBuss1?.password}
                  onChange={(e) => {
                    hendleChangerole1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">New Password</label>
                <input
                  name="newPassword"
                  className="form-control"
                  placeholder="Enter New Password"
                  type="text"
                  value={userBuss1?.newPassword}
                  onChange={(e) => {
                    hendleChangerole1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Confirm Password</label>
                <input
                  name="confirmPassword"
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  type="text"
                  value={userBuss1?.confirmPassword}
                  onChange={(e) => {
                    hendleChangerole1(e);
                  }}
                />
              </div>
              
            </div>
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct1();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Account holder</label>
                <input
                  name="acc_holder"
                  className="form-control"
                  placeholder="Enter Account holder"
                  type="text"
                  value={userBuss?.acc_holder}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Bank name</label>
                <input
                  name="bank_name"
                  className="form-control"
                  placeholder="Enter bank name"
                  type="text"
                  value={userBuss?.bank_name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">branch name</label>
                <input
                  name="branch_name"
                  className="form-control"
                  placeholder="Enter branch name"
                  type="text"
                  value={userBuss?.branch_name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Enter account</label>
                <input
                  name="acc_no"
                  className="form-control"
                  placeholder="Enter account"
                  type="text"
                  value={userBuss?.acc_no}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">IFSC</label>
                <input
                  name="ifsc"
                  className="form-control"
                  placeholder="Enter IFSC"
                  type="text"
                  value={userBuss?.ifsc}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Pan card</label>
                <input
                  name="pan_card"
                  className="form-control"
                  placeholder="Enter pan card"
                  type="text"
                  value={userBuss?.pan_card}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <Col lg={24} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="row border-bottom pt-10 pb-20">
            <div className="col-6">Bank Information</div>
            <div className="col-6">
              {" "}
              {/* <span className="jop_btn ant-tooltip-open" onClick={handleShow}>
                Update Profile Image
              </span> */}
              <div className="flex1 flex justify-content-end align-items-center">
              <Tooltip title="Update">
                  <span className="jop_btn" onClick={handleShow1}>
                    Change Password
                  </span>
                </Tooltip>
                <Tooltip title="Update">
                  <span className="jop_btn ml-3" onClick={handleShow}>
                    Update
                  </span>
                </Tooltip>
                
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center pt-20 pb-20">
            {/* <div className="col-12 ">
              <div style={{ width: "180px" }}>
               
                <span className="jop_btn ant-tooltip-open" onClick={handleShow}>
                  Update Profile Image
                </span>
                
              </div>
            </div>
            <div className="col-3">
              <div style={{ width: "100px" }}>
                <img
                  src={URL.API_BASE_URL + get_vendor_profiles?.sellerProfile}
                  alt=""
                  style={{ width: "100%" }}
                />
          
              </div>
            </div> */}
            {/* <div className="col-9">
              <p>name : {get_vendor_profiles?.fullName}</p>
              <p>Bussiness Name : {get_vendor_profiles?.displayName}</p>
              <p>Phone : {get_vendor_profiles?.phone}</p>
              <p>
                Address : {get_vendor_profiles?.city},
                {get_vendor_profiles?.state},{get_vendor_profiles?.zip}
              </p>
              
            </div> */}
            <div className="col-sm-6 col-12 bankdetails">
              <div
                class="card bank-info-card bg-bottom bg-contain bg-img"
                style={{
                  backgroundImage:
                    "url(https://casandeepsharma.in.net/public/assets/back-end/img/bank-info-card-bg.png)",
                }}
              >
                <div class="border-bottom p-3">
                  <h4 class="mb-0 fw-semibold">
                    Holder Name :{" "}
                    <strong>
                      {" "}
                      {get_vendor_profiles?.kyc_id?.acc_holder
                        ? get_vendor_profiles?.kyc_id?.acc_holder
                        : " No Data found"}{" "}
                    </strong>
                  </h4>
                </div>

                <div class="card-body position-relative">
                  <img
                    class="bank-card-img"
                    width="78"
                    src="https://casandeepsharma.in.net/public/assets/back-end/img/bank-card.png"
                    alt=""
                  />

                  <ul class="list-unstyled d-flex flex-column gap-4">
                    <li>
                      <h3 class="mb-2">Bank Name :</h3>
                      <div>
                        {get_vendor_profiles?.kyc_id?.bank_name
                          ? get_vendor_profiles?.kyc_id?.bank_name
                          : " No Data found"}
                      </div>
                    </li>
                    <li>
                      <h3 class="mb-2">Branch Name :</h3>
                      <div>
                        {get_vendor_profiles?.kyc_id?.branch_name
                          ? get_vendor_profiles?.kyc_id?.branch_name
                          : " No Data found"}
                      </div>
                    </li>
                    <li>
                      <h3 class="mb-2">Account Number : </h3>
                      <div>
                        {get_vendor_profiles?.kyc_id?.acc_no
                          ? get_vendor_profiles?.kyc_id?.acc_no
                          : " No Data found"}
                      </div>
                    </li>
                    <li>
                      <h3 class="mb-2">IFSC : </h3>
                      <div>
                        {get_vendor_profiles?.kyc_id?.ifsc
                          ? get_vendor_profiles?.kyc_id?.ifsc
                          : " No Data found"}
                      </div>
                    </li>
                    <li>
                      <h3 class="mb-2">Pan card : </h3>
                      <div>
                        {get_vendor_profiles?.kyc_id?.pan_card
                          ? get_vendor_profiles?.kyc_id?.pan_card
                          : " No Data found"}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default BankBox;
